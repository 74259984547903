import React, { useContext, useEffect, useState, useRef } from "react";
import "./matrix.css";
import "./styles.css";
import axios from '../api/axios';
import { getFateMatrix } from "../utils/fateMatrix";
import MatrixDiagram from "../components/matrix/matrixDiagram";
import MatrixTable from "../components/matrix/matrixTable";
import icon145 from "./resources/icon145.png";
import matrixSvgImg from "../components/matrix/Matrix3.svg";
import moon from "./resources/moon8.png";
import AuthContext from "../context/AuthProvider";
import { useNavigate, NavLink as Link } from "react-router-dom";
import { useLanguage } from '../context/LanguageContext';

const Matrix = () => {

    const [matrix, setMatrix] = useState('');
    const [matrixSvg, setMatrixSvg] = useState('');
    const [matrixTable, setMatrixTable] = useState('');
    const matrixFirstPageData = [12,23,17,7,14,21,10,10,6,8,20,4,21,8,22,3,6,20,8,4,18,22,19,6,7,6,22,7,10,11,14,19,6,7,8,8,18,10,10,7,6];
    const { auth, setAuth } = useContext(AuthContext);
    const { backendUrl } = useContext(AuthContext);
    const { language, changeLanguage, trans } = useLanguage();

    let navigate = useNavigate();

    const [arcaneDescription, setArcaneDescription] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);
    const [limited, setLimited] = useState(false);

    var inputLength = 0;
    const ARCANES_URL = '/arcanes';
    const ARCANESPROTECTED_URL = '/arcanesProtected';
  
    useEffect(() => {
      if (matrix !== "") {
        console.log(matrix);
        setMatrixSvg(<MatrixDiagram data={matrix} />);
        setMatrixTable(<MatrixTable data={matrix} date={document.getElementById("dateInput").value} name={document.getElementById("nameInput").value}/>);
        document.getElementById("firstPage").style.display = "none";
        document.getElementById("secondPage").style.display = "";
        document.getElementById("matrixPage").style.position = "static";
      } else {
        setMatrixSvg(null);
        setMatrixTable(null);
        document.getElementById("firstPage").style.display = "";
        document.getElementById("secondPage").style.display = "none";
        document.getElementById("matrixPage").style.position = "fixed";
      }
    }, [matrix]);

    const getArcane = (x, y) => {
      let element = x + y;
      if(element > 22){
          element = String(parseInt(String(element).split('')[0], 10) + parseInt(String(element).split('')[1], 10));
      }
      return(element);
    }

    const getArcaneMath = (x, y) => {
      let result = String(x) + " + " + String(y) + " = ";
      let element = x + y;
      if(element > 22){
        result += String(element).split('')[0] + " + " + String(element).split('')[1] + " = ";
      }
      return(result);
    }

    const removeCharactersBeforeColon = (str) => {
      const colonIndex = str.indexOf(':');
      return colonIndex !== -1 ? str.substring(colonIndex + 1).trim() : str;
    };
  
  

    function autoPeriod(event){
      let inputElement = document.getElementById("dateInput");
      let x = inputElement.value;
      let result = '';
      for (let i of x) {
          if (((!isNaN(i) && i !== ' ') || i === '.') && result.length < 10) {
              result += i;
          }
      }
      inputElement.value = result;

      x = String(x).length;
      if( (x === inputLength + 1) && (x === 2 || x === 5) ){
        document.getElementById("dateInput").value = document.getElementById("dateInput").value + '.';
        x = x + 1;
      }
      inputLength = x;
    }

    async function getArcanesDescriptions(arcs){
      let data = [
        arcs[0], arcs[1], getArcane(arcs[0], arcs[1]),
        arcs[10], arcs[8], getArcane(arcs[10], arcs[8]),
        arcs[9], arcs[7], getArcane(arcs[9], arcs[7]),
        arcs[26], arcs[25], getArcane(arcs[26], arcs[25]),
        arcs[4], arcs[4], getArcane(arcs[4], arcs[4]),
        arcs[11], arcs[5], getArcane(arcs[11], arcs[5]),
        arcs[2], arcs[3], getArcane(arcs[2], arcs[3]),
      ];
      try {
        let response;
        if(auth && auth.user && auth.accessToken){
          response = await axios.post(ARCANESPROTECTED_URL,
            JSON.stringify({ "user": auth.user, "token": auth.accessToken, data, "lang": language }),
            {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth.accessToken}` },
                withCredentials: true, 
                timeout: 27000
            }
          );
          response = response.data.data;
          response = response.map(removeCharactersBeforeColon);
          setLoggedIn(true);
          setLimited(false);
        } else {
          response = await axios.post(ARCANES_URL,
            JSON.stringify({ data, "lang": language }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true, 
                timeout: 27000
            }
          );
          response = response.data.data;
          response = response.map(removeCharactersBeforeColon);
          response[3] = trans('PremiumRequired');
          response[4] = trans('PremiumRequired');
          response[5] = trans('PremiumRequired');
          response[6] = trans('PremiumRequired');
          response[7] = trans('PremiumRequired');
          response[8] = trans('PremiumRequired');
          response[9] = trans('PremiumRequired');
          setLoggedIn(false);
          setLimited(false);
        }
        
        setArcaneDescription(response);
      } catch (error) {
        if (error.response && error.response.status === 429) {
          setLimited(true);
        } else if(error.response && error.response.status === 403) {
          setLimited(false);
          setAuth(null);
          localStorage.setItem("auth", JSON.stringify(null));
          //navigate('/login');
          await getArcanesDescriptions(arcs);
        }
      }
    }
  
    async function handleClick(event){
      let x = document.getElementById("dateInput").value;
      let y = document.getElementById("nameInput").value;
      x = String(x).split('.');
      if(!x[0] || !x[1] || !x[2] || !y){
        return;
      }
      var arcanes = getFateMatrix(x[0], x[1], x[2], "");
      setMatrix(arcanes);
      await getArcanesDescriptions(arcanes);
      console.log(arcanes);
    }

    function handleExpand(event, number){
      let element;
      for (let i = 0; i < 10; i++) {
        element = document.getElementById("matrixExpandableTab" + String(i));
        if (element && i !== number) {
          element.style.maxHeight = "0px";
          document.getElementById("matrixResultsButton" + String(i)).style.borderRadius = '1rem';
          element.style.padding = '0px 0px';
        }
      }
      element = document.getElementById("matrixExpandableTab" + String(number));
      if(element){
        if(element.style.maxHeight === '0px' || element.style.maxHeight === ''){
          element.style.maxHeight = '220px';
          document.getElementById("matrixResultsButton" + String(number)).style.borderRadius = '1rem 1rem 0 0';
          element.style.padding = '10px 10px';
        } else {
          element.style.maxHeight = "0px";
          document.getElementById("matrixResultsButton" + String(number)).style.borderRadius = '1rem';
          element.style.padding = '0px 0px';
        }
      }
      
    };

    return (
      <div id="matrixPage" className="mainMatrixContainer">
        <div id="firstPage">
          <span className="headerFont mainMatrixHeader">{trans('MatrixHeader')}</span>

          <span className="secondFont blockStyle">{trans('name')}</span>
          <input className="inputField" id="nameInput" type="text"></input>

          <span className="secondFont blockStyle">{trans('dateofbirth')}</span>
          <input className="inputField" id="dateInput" type="text" placeholder={trans('dmy')} onChange={(e) => { autoPeriod(e) }}></input>

          { false ?  
            <>
              <span className="secondFont blockStyle">{trans('malefemale')}</span>
              <input className="inputField" type="text"></input> 
            </>
            :
            <></>
          }

          <button className="buttonStyle" onClick={(e) => { handleClick(e) }}>{trans('Calculate')}</button>
          <img src={moon} className="matrixImageFirstPage"></img>
        </div>

        <div id="secondPage">

          <div style={{display: "flex", align_items: "start"}}>
          <div style={{display: "inline-block", width: "40%", overflow: "hidden"}}>
            <span className="headerFont mainMatrixHeader yourMatrix">{trans('YourDestinyMatrix')}</span>
            {matrixTable}
          </div>
          <div style={{display: "inline-block", width: "40%", overflow: "hidden", marginLeft: "10%", marginTop: "20px"}}>
            {matrixSvg}
          </div>
          </div>

          <div className="matrixPurposeContainer">
            <div style={{display: "inline-block", width: "30%", aspectRatio:"4/1"}}>
              <div className="forthFont">{trans('PersonalPurpose')}</div>
              <div className="fifthFont mediumFontSize" style={{marginTop: "2vw", width: "100%", height: "5vw"}}>{trans('personsGoal')}</div>
              <div className="sixthFont mediumFontSize" style={{marginTop: "2vw", width: "100%"}}>({trans('upto40')})</div>
              <div className="scalesGrid">
                <img src={icon145} className="scalesImage"></img>
                <div className="seventhFont smallFontSize" style={{position: "absolute", top: "17%", left: "0%"}}>{trans('Sky')}</div>
                <div className="seventhFont smallFontSize" style={{position: "absolute", top: "17%", left: "17%"}}>{trans('Earth')}</div>
                <div className="circle" style={{position: "absolute", top: "19%", left: "10%"}}><span style={{position: "relative", left: "-1px", top: "-1px"}}>{getArcane(matrix[1], matrix[3])}</span></div>
                <div className="circle" style={{position: "absolute", top: "19%", left: "30%"}}><span style={{position: "relative", left: "-1px", top: "-1px"}}>{getArcane(matrix[0], matrix[2])}</span></div>
                <div className="circle" style={{position: "absolute", top: "19%", left: "37.58%"}}><span style={{position: "relative", left: "-1px", top: "-1px"}}>{matrix[30]}</span></div>
              </div>
            </div>
            <div style={{display: "inline-block", marginLeft: "min(20px, 4%)", width: "30%", aspectRatio:"4/1"}}>
              <div className="forthFont">{trans('SocialPurpose')}</div>
              <div className="fifthFont mediumFontSize" style={{marginTop: "2vw", width: "100%", height: "5vw"}}>{trans('personsGoal2')}</div>
              <div className="sixthFont mediumFontSize" style={{marginTop: "2vw", width: "100%"}}>({trans('after40')})</div>
              <div className="scalesGrid">
                <img src={icon145} className="scalesImage"></img>
                <div className="seventhFont  smallFontSize" style={{position: "absolute", top: "17%", left: "3%"}}>M</div>
                <div className="seventhFont  smallFontSize" style={{position: "absolute", top: "17%", left: "22%"}}>F</div>
                <div className="circle" style={{position: "absolute", top: "19%", left: "8%"}}><span style={{position: "relative", left: "-1px", top: "-1px"}}>{getArcane(matrix[13], matrix[16])}</span></div>
                <div className="circle" style={{position: "absolute", top: "19%", left: "27%"}}><span style={{position: "relative", left: "-1px", top: "-1px"}}>{getArcane(matrix[19], matrix[22])}</span></div>
                <div className="circle" style={{position: "absolute", top: "19%", left: "37.58%"}}><span style={{position: "relative", left: "-1px", top: "-1px"}}>{matrix[31]}</span></div>
              </div>
            </div>
            <div style={{display: "inline-block", marginLeft: "min(20px, 4%)", width: "30%", aspectRatio:"4/1"}}>
              <div className="forthFont">{trans('SpiritualPurpose')}</div>
              <div className="fifthFont mediumFontSize" style={{marginTop: "2vw", width: "100%", height: "5vw"}}>{trans('personsGoal3')}</div>
              <div className="sixthFont mediumFontSize" style={{marginTop: "2vw", width: "100%"}}>({trans('after60')})</div>
              <div style={{height: "100%", position: "relative"}}>
                <div className="seventhFont smallFontSize" style={{display:"inline", position: "absolute", top: "17%"}}>{getArcaneMath(matrix[30], matrix[31])}
                <div className="circle" style={{display:"inline", position: "absolute", top: "17%", marginLeft:"2%"}}><span style={{position: "relative", left: "-1px", top: "0px"}}>{matrix[32]}</span></div></div>
              </div>
            </div>
          </div>

          {loggedIn ? <div className="forthFont matrixForUser">{trans('resultFor')} {auth.user}</div> : arcaneDescription[0] ? <div className="forthFont matrixForUser">{trans('resultFor')} {trans('guests')}</div> : <></>}
          <div className="matrixResultsContainer">
            <div id="matrixResultsButton0" className="matrixResultsButton forthFont" onClick={(e) => {handleExpand(e, 0)}}>{trans('BusinessCard')}</div>
            <div id="matrixExpandableTab0" className="matrixExpandableTab">{arcaneDescription[0] ? <>{arcaneDescription[0]}</> : !limited ? <>{trans('Calculating')}</> : <>{trans('Limited')}</>}</div>
            <div id="matrixResultsButton1" className="matrixResultsButton forthFont" onClick={(e) => {handleExpand(e, 1)}}>{trans('PastKarma')}</div>
            <div id="matrixExpandableTab1" className="matrixExpandableTab">{arcaneDescription[1] ? <>{arcaneDescription[1]}</> : !limited ? <>{trans('Calculating')}</> : <>{trans('Limited')}</>}</div>
            <div id="matrixResultsButton2" className="matrixResultsButton forthFont" onClick={(e) => {handleExpand(e, 2)}}>{trans('SoulStudy')}</div>
            <div id="matrixExpandableTab2" className="matrixExpandableTab">{arcaneDescription[2] ? <>{arcaneDescription[2]}</> : !limited ? <>{trans('Calculating')}</> : <>{trans('Limited')}</>}</div>
            <div id="matrixResultsButton3" className="matrixResultsButton forthFont" onClick={(e) => {handleExpand(e, 3)}}>{trans('TaskUntil40')}</div>
            <div id="matrixExpandableTab3" className="matrixExpandableTab">{arcaneDescription[3] ? <>{arcaneDescription[3]}</> : !limited ? <>{trans('Calculating')}</> : <>{trans('Limited')}</>}</div>
            <div id="matrixResultsButton4" className="matrixResultsButton forthFont" onClick={(e) => {handleExpand(e, 4)}}>{trans('Money')}</div>
            <div id="matrixExpandableTab4" className="matrixExpandableTab">{arcaneDescription[4] ? <>{arcaneDescription[4]}</> : !limited ? <>{trans('Calculating')}</> : <>{trans('Limited')}</>}</div>
            <div id="matrixResultsButton5" className="matrixResultsButton forthFont" onClick={(e) => {handleExpand(e, 5)}}>{trans('Relationship')}</div>
            <div id="matrixExpandableTab5" className="matrixExpandableTab">{arcaneDescription[5] ? <>{arcaneDescription[5]}</> : !limited ? <>{trans('Calculating')}</> : <>{trans('Limited')}</>}</div>
            <div id="matrixResultsButton6" className="matrixResultsButton forthFont" onClick={(e) => {handleExpand(e, 6)}}>{trans('MentalComfort')}</div>
            <div id="matrixExpandableTab6" className="matrixExpandableTab">{arcaneDescription[6] ? <>{arcaneDescription[6]}</> : !limited ? <>{trans('Calculating')}</> : <>{trans('Limited')}</>}</div>
            <div id="matrixResultsButton7" className="matrixResultsButton forthFont" onClick={(e) => {handleExpand(e, 7)}}>{trans('GenericTasks')}</div>
            <div id="matrixExpandableTab7" className="matrixExpandableTab">{arcaneDescription[7] ? <>{arcaneDescription[7]}</> : !limited ? <>{trans('Calculating')}</> : <>{trans('Limited')}</>}</div>
            <div id="matrixResultsButton8" className="matrixResultsButton forthFont" onClick={(e) => {handleExpand(e, 8)}}>{trans('Talents')}</div>
            <div id="matrixExpandableTab8" className="matrixExpandableTab">{arcaneDescription[8] ? <>{arcaneDescription[8]}</> : !limited ? <>{trans('Calculating')}</> : <>{trans('Limited')}</>}</div>
            <div id="matrixResultsButton9" className="matrixResultsButton forthFont" onClick={(e) => {handleExpand(e, 9)}}>{trans('Sexuality')}</div>
            <div id="matrixExpandableTab9" className="matrixExpandableTab">{arcaneDescription[9] ? <>{arcaneDescription[9]}</> : !limited ? <>{trans('Calculating')}</> : <>{trans('Limited')}</>}</div>
          </div>

        </div>
        
      </div>

    );
}

export default Matrix;