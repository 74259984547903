import React from 'react';
import './App.css';
import Navbar from './components/navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Matrix from './pages/matrix';
import LogIn from './pages/login';
import SignUp from './pages/signup';
import Profile from './pages/profile';
import Contact from './pages/contact';
import Taro from './pages/taro';
import NotFound from './pages/404';
import Chakras from './pages/chakras';
import { AuthProvider } from './context/AuthProvider';
import { LanguageProvider } from './context/LanguageContext';
import CookieConsent from './utils/CookieConsent';

function App() {
  //document.body.style.overflow = 'hidden';
  document.body.style.overflowX = 'hidden';
  
  return (
    <AuthProvider>
      <LanguageProvider>
        <CookieConsent />
        <Router>
          <Navbar />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/matrix' element={<Matrix />} />
            <Route exact path='/login' element={<LogIn />} />
            <Route exact path='/sign-up/*' element={<SignUp />} />
            <Route exact path='/profile/*' element={<Profile />} />
            <Route exact path='/404' element={<NotFound />} />
            <Route exact path='/chakras' element={<Chakras />} />
            <Route exact path='/contact' element={<Contact />} />
            <Route exact path='/taro' element={<Taro />} />
            <Route path='*' element={<Home />} />
            <Route component={Home} />
          </Routes>
        </Router>
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;
