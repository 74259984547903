
export function getFateMatrix(day, month, year, name) {
    const arcane = [];
    function addArcane(x, y){
        let element = x + y;
        if(element > 22){
            element = parseInt(String(element).split('')[0], 10) + parseInt(String(element).split('')[1], 10)
        }
        arcane.push(element);
    }
    month = parseInt(month);
    day = parseInt(day);
    year = parseInt(year);
    
    if(day > 22){
        day = parseInt(String(day).split('')[0], 10) + parseInt(String(day).split('')[1], 10);
    }
    arcane.push(day);
    arcane.push(month);
    while(year > 22){
        let newYear = 0;
        for(let i of String(year).split('')){
            newYear = newYear + parseInt(i, 10);
        }
        year = newYear;
    }
    arcane.push(year);
    let bottomPoint = day + month + year;
    if(bottomPoint > 22){
        bottomPoint = parseInt(String(bottomPoint).split('')[0], 10) + parseInt(String(bottomPoint).split('')[1], 10)
    }
    arcane.push(bottomPoint);
    let center = day + month + year + bottomPoint;
    if(center > 22){
        center = parseInt(String(center).split('')[0], 10) + parseInt(String(center).split('')[1], 10)
    }
    arcane.push(center);
    let botTail = bottomPoint + center;
    if(botTail > 22){
        botTail = parseInt(String(botTail).split('')[0], 10) + parseInt(String(botTail).split('')[1], 10)
    }
    arcane.push(botTail);
    let botMidTail = botTail + bottomPoint;
    if(botMidTail > 22){
        botMidTail = parseInt(String(botMidTail).split('')[0], 10) + parseInt(String(botMidTail).split('')[1], 10)
    }
    arcane.push(botMidTail);
    let topTail = month + center;
    if(topTail > 22){
        topTail = parseInt(String(topTail).split('')[0], 10) + parseInt(String(topTail).split('')[1], 10)
    }
    arcane.push(topTail);
    let topMidTail = topTail + month;
    if(topMidTail > 22){
        topMidTail = parseInt(String(topMidTail).split('')[0], 10) + parseInt(String(topMidTail).split('')[1], 10)
    }
    arcane.push(topMidTail);
    let leftTail = day + center;
    if(leftTail > 22){
        leftTail = parseInt(String(leftTail).split('')[0], 10) + parseInt(String(leftTail).split('')[1], 10)
    }
    arcane.push(leftTail);
    let leftMidTail = day + leftTail;
    if(leftMidTail > 22){
        leftMidTail = parseInt(String(leftMidTail).split('')[0], 10) + parseInt(String(leftMidTail).split('')[1], 10)
    }
    arcane.push(leftMidTail);
    let rightTail = year + center;
    if(rightTail > 22){
        rightTail = parseInt(String(rightTail).split('')[0], 10) + parseInt(String(rightTail).split('')[1], 10)
    }
    arcane.push(rightTail);
    let rightMidTail = year + rightTail;
    if(rightMidTail > 22){
        rightMidTail = parseInt(String(rightMidTail).split('')[0], 10) + parseInt(String(rightMidTail).split('')[1], 10)
    }
    arcane.push(rightMidTail);

    addArcane(arcane[0], arcane[1]);
    addArcane(arcane[13], arcane[4]);
    addArcane(arcane[14], arcane[13]);
    addArcane(arcane[2], arcane[3]);
    addArcane(arcane[4], arcane[16]);
    addArcane(arcane[17], arcane[16]);
    addArcane(arcane[1], arcane[2]);
    addArcane(arcane[19], arcane[4]);
    addArcane(arcane[19], arcane[20]);
    addArcane(arcane[0], arcane[3]);
    addArcane(arcane[22], arcane[4]);
    addArcane(arcane[22], arcane[23]);
    addArcane(arcane[7], arcane[4]);
    addArcane(arcane[9], arcane[4]);
    addArcane(arcane[11], arcane[5]);
    addArcane(arcane[27], arcane[5]);
    addArcane(arcane[27], arcane[11]);
    let sky = arcane[1] + arcane[3];
    if(sky > 22){
        sky = parseInt(String(sky).split('')[0], 10) + parseInt(String(sky).split('')[1], 10)
    }
    let earth = arcane[0] + arcane[2];
    if(earth > 22){
        earth = parseInt(String(earth).split('')[0], 10) + parseInt(String(earth).split('')[1], 10)
    }
    addArcane(sky, earth);
    sky = arcane[19] + arcane[22];
    if(sky > 22){
        sky = parseInt(String(sky).split('')[0], 10) + parseInt(String(sky).split('')[1], 10)
    }
    earth = arcane[13] + arcane[16];
    if(earth > 22){
        earth = parseInt(String(earth).split('')[0], 10) + parseInt(String(earth).split('')[1], 10)
    }
    addArcane(sky, earth);
    addArcane(arcane[30], arcane[31]);
    addArcane(arcane[32], arcane[31]);
    addArcane(arcane[0], arcane[1]);
    addArcane(arcane[8], arcane[10]);
    addArcane(arcane[7], arcane[9]);
    addArcane(arcane[26], arcane[25]);
    addArcane(arcane[4], arcane[4]);
    addArcane(arcane[11], arcane[5]);
    addArcane(arcane[2], arcane[3]);
    return arcane;
}