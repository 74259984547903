import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { NavLink as Link } from "react-router-dom";
import "../navbar/index.css";
import AuthContext from "../../context/AuthProvider";
import axios from "../../api/axios";
import io from 'socket.io-client';
import "./chat.css";
import defaultIcon from '../../pages/resources/defaultIcon.png';

if (JSON.parse(localStorage.getItem("auth"))?.user) {
    var firstUserName = JSON.parse(localStorage.getItem("auth")).user;
}

//const socket = io("https://serverfatisarcanum.azurewebsites.net/");
/*
socket.on('connect', () => {
    console.log('Connected to server');
    socket.emit('register', firstUserName);
});
*/
var secondUserName = "admin";

const Chat = () => {

    const [ tick, setTick ] = useState(0);

    const [message, setMessage] = useState('');
    
    const [chatHistory, setChatHistory] = useState([]);
    /*
    socket.on('message', () => {
        setTick(tick + 1);
      });
      */

    const { auth } = useContext(AuthContext);
    const { backendUrl } = useContext(AuthContext);
    //const secondUserName = JSON.parse(localStorage.getItem("secondUserName"));

    const imageExists = async (url) => {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        if(response.status === 406){
          return false;
          
        } else {
          return true;
        }
      } catch (error) {
          console.log("exception called!");
          return false;
      }
    };

    if(JSON.parse(localStorage.getItem("auth"))?.user !== null){
      firstUserName = JSON.parse(localStorage.getItem("auth"))?.user;
    }
    let firstImage = imageExists(`${backendUrl}/img/${firstUserName}`) === false ? `${backendUrl}/img/${firstUserName}` : defaultIcon;
    let secondImage = imageExists(`${backendUrl}/img/${firstUserName}`) === false ? `${backendUrl}/img/${firstUserName}` : defaultIcon; // TODO

      
    useEffect(() => {
      /*
        const socket = io("https://serverfatisarcanum.azurewebsites.net/");
        socket.on('connect', () => {
            console.log('Connected to server');
            socket.emit('register', firstUserName);
            socket.on('message', () => {
                setTick(tick + 1);
              });
        });
        socket.emit('register', firstUserName);
        socket.on('message', () => {
        setTick(tick + 1);
    });
    */
    }, [auth]);
    
      useEffect(() => {
        /*
        socket.on('connect', () => {
          console.log('Connected to server');
          socket.emit('register', firstUserName);
          socket.on('message', () => {
            setTick(tick + 1);
          });
        });    
        socket.emit('register', firstUserName);
        socket.on('message', () => {
        setTick(tick + 1);
        });
        */
      }, []); 

      useEffect(() => {
        document.getElementById("messagesContainer").scrollTop = document.getElementById("messagesContainer").scrollHeight;
      }, [chatHistory]);

      function handleChange(event) {
        if(event.target.value[event.target.value.length-1] !== '\n'){
          setMessage(event.target.value);
          event.target.style.height = 'auto';
          event.target.style.height = `${event.target.scrollHeight}px`;
        } else {
          sendMessage(event, document.getElementById('chatInput').value); 
          setMessage('');
        }
      }

      useEffect(() => {
        /*
        console.log(typeof(secondUserName));
        if (typeof(secondUserName) == "string" && secondUserName.length > 0) {
          console.log('sending request...');
    
          axios.get(`/chat/${firstUserName}/${secondUserName}`).then(response => {
            setChatHistory(response.data);
            console.log(response.data);
          }).catch(error => {
            console.log(error);
          });
        }
        */
      }, [firstUserName, secondUserName, tick]);

    
      const chatMessagesArray = () => {
        const array = [];
        if(chatHistory !== null && typeof(chatHistory) !== "string"){
          for(let i of chatHistory){
            array.push(
            <div key={i} className={i.senderName === firstUserName ? "rightMessage" : "leftMessage"}>
              <div className="inlineContainer">
                {
                  i.senderName === firstUserName ?
                  <>
                    <div className="senderName" style={{textAlign: "right"}}>{i.senderName}</div>
                    <img className='profileImg' src={firstImage}></img>
                  </> :
                  <>
                    <img className='profileImg' src={firstImage}></img>
                    <div className="senderName">{i.senderName}</div>
                  </>
                }
              </div>
              <div className="messageText">{i.messageText}</div>
           </div>
           );
          }
          return(
            array
          );
        } else {
          return(<p>Loading...</p>);
        }
      };

    const sendMessage = async (event, text) => {
        try {
            event.preventDefault();
            await axios.post(`/chatSendMessage/${firstUserName}/${secondUserName}`,
                JSON.stringify({ text }),
                {
                    headers: { 'Content-Type': 'application/json' }
                });
            console.log("register socket ->");
            //socket.emit('register', firstUserName);
            console.log("reload socket ->");
            //socket.emit('reloadChat', [firstUserName, secondUserName]);
            setTick(tick + 1);
        } catch (error) {
            console.log(error);
        }

    }

    function handleUserSelect(event) {
        secondUserName = event.target.value;
        console.log("change user to:");
        console.log(secondUserName);
    }
    return(
        <>
            <div id="messagesContainer" className="messagesClass">
                <input style={{display: "none"}} onChange={(e) => {handleUserSelect(e)}}></input>
                {chatMessagesArray()}
            </div>
            <div className="inputAreaChat">
                <textarea id="chatInput" type="text" placeholder="Write your message here..." value={message} onChange={handleChange}></textarea>
                <div className="chatButton" onClick={(e) => { sendMessage(e, document.getElementById('chatInput').value); setMessage('') }}>Send</div>
            </div>
        </>
    );
}

export default Chat;