import { React, useRef, useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthProvider';
import { useNavigate } from "react-router-dom";
import "./auth.css";
import axios from '../api/axios';
import { useLanguage } from '../context/LanguageContext';
   
const LOGIN_URL = '/auth';

const LogIn = () => {

  const { auth, setAuth } = useContext(AuthContext);
  const { language, changeLanguage, trans } = useLanguage();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');

  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.body.style.overflow = 'auto';
    document.body.style.overflowX = 'hidden';
  };



  const submitHandler = async (data) => {
    data =  { user: data[0],  pwd: data[1]};

    try {
      const response = await axios.post(LOGIN_URL,
          JSON.stringify({ user, pwd }),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true, 
              timeout: 3000
          }
      );
      //console.log(response?.data);
      //console.log(response?.accessToken);
      //console.log(JSON.stringify(response));
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const email = response?.data?.email;
      setAuth({ user, email, pwd, roles, accessToken });
      localStorage.setItem("auth", JSON.stringify({ user, email, pwd, roles, accessToken }));
      setUser('');
      setPwd('');
      document.getElementById('passwordInput').value = '';
      document.getElementById('username').value = '';
      navigate("/profile/");
  } catch (err) {
      if (!err?.response) {
          setErrMsg(trans('NoServerResponse'));
          console.log(err)
      } else if (err.response?.status === 400) {
          setErrMsg('Missing username or password');
      } else {
          setErrMsg(trans('AuthenticationFailed'));
      }
      errRef.current.focus();
  }
    /*
    fetch("/auth", {method: 'POST',
    headers:{
    'Content-Type':'application/json'
    },
    body: JSON.stringify(data)})
    */
  }

  let navigate = useNavigate();
  const routeChangeToggle = () => {
      let path = "../sign-up";
      navigate(path);
  }

  return (
    <div className='mainAuthContainer'>
      <form className='SignupForm'>
        <div className='seventhFont profileFS7' style={{fontSize: "4vw", textAlign: "center"}}>{trans('Welcome')}</div>
        <div className='forthFont profileFS46' style={{fontSize: "2.6vw", textAlign: "center"}}>{trans('Nicetoseeyouagain')}</div>
        <div className='authSwitchContainer'>
          <div className='authSwitchItem' style={{backgroundColor: "#4D4DC2"}}>{trans('LogIn')}</div>
          <div className='authSwitchItem' onClick={routeChangeToggle}>{trans('SignUp')}</div>
        </div>
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        <input id='username' className='InputFieldAuth' placeholder={trans('Name')} type='text' ref={userRef} autoComplete="off" value={user} required onChange={(event) => { setUser(event.target.value) }}></input>
        <input id='passwordInput' className='InputFieldAuth' placeholder={trans('Password')} type='password' value={pwd} required onChange={(event) => { setPwd(event.target.value) }}></input>
        
        <div className='SubmitButton seventhFont' onClick={() => { submitHandler([user, pwd]) }}>{trans('LogIn')}</div>
      </form>
    </div>
  );
};
  
export default LogIn;