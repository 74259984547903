import React, { useContext, useEffect, useState, useRef } from "react";
import "./matrix.css";
import "./styles.css";
import axios from '../api/axios';
import { getFateMatrix } from "../utils/fateMatrix";
import MatrixDiagram from "../components/matrix/matrixDiagram";
import MatrixTable from "../components/matrix/matrixTable";
import icon145 from "./resources/icon145.png";
import matrixSvgImg from "../components/matrix/Matrix3.svg";
import moon from "./resources/moon8.png";
import AuthContext from "../context/AuthProvider";
import { useLanguage } from '../context/LanguageContext';

const Contact = () => {

    const { auth } = useContext(AuthContext);
    const { backendUrl } = useContext(AuthContext);
    const { language, changeLanguage, trans } = useLanguage();


    return (
      <div id="matrixPage" className="mainMatrixContainer">
        <div id="firstPage">
          <span className="headerFont mainMatrixHeader">{trans('Contact')} Fatis Arcanum</span>

          <span className="secondFont blockStyle">fatisarcanum@gmail.com</span>
          <img src={moon} className="matrixImageFirstPage"></img>
        </div>
      </div>
    );
}

export default Contact;