import React, { createContext, useState, useContext } from 'react';

const LanguageContext = createContext();

const translations = {
    en: {
        hello: "Hello",
        Welcome: "Welcome",
        Home: "Home",
        MatrixHeader: "Destiny Matrix Calculator Online",
        MatrixofDestiny: "Matrix of Destiny",
        Meditations: "Meditations",
        Taro: "Tarot",
        name: "name",
        dateofbirth: "date of birth",
        malefemale: "male/female",
        dmy: "day.month.year",
        Calculate: "Calculate",
        Learn: "Learn",
        Listen: "Listen",
        YourDestinyMatrix: "Your Destiny Matrix",
        PersonalPurpose: "Personal Purpose",
        SocialPurpose: "Social Purpose",
        SpiritualPurpose: "Spiritual Purpose",
        upto40: "up to 40 years old",
        after40: "after 40 years",
        after60: "after 60 years",
        Sky: "Sky",
        Earth: "Earth",
        personsGoal: "By combining the Spiritual and Material goals of an individual, we obtain Personal.",
        personsGoal2: "What is useful that we give to the outside world.",
        personsGoal3: "If you have worked on the Personal and Social, then the Spiritual is given as a bonus in plus.",
        resultFor: "Result for",
        guests: "guests",
        BusinessCard: "Business card",
        PastKarma: "Karma of a past incarnation",
        SoulStudy: "Main study of the soul",
        TaskUntil40: "The task of the soul until 40 years old",
        Money: "Money",
        Relationship: "Relationship",
        MentalComfort: "Point of mental comfort",
        GenericTasks: "Generic tasks",
        Talents: "Talents",
        Sexuality: "Sexuality",
        MainPageText1: "Our website is your door to a world of opportunity. We use a unique algorithm that not only helps you understand yourself better, but also provides you with detailed predictions and tips for effectively achieving success in various areas of life. If you're looking for ways to take your life to the level you\'ve always envied, then we have all the tools and possibilities for you. Let\'s start the adventure with Matrix of Destiny!",
        MainPageText2: "Solve the mysteries of your past, present and future with our tarot cards! Our website is open 24/7, without breaks for lunch or holidays. We always have useful information about the cards at the ready, as well as how to place them correctly for the highest accuracy. We can help you uncover your darkest secrets, predict the future, and find answers to your toughest questions.",
        MainPageText3: "On our website you can learn how to practice meditation and explore different techniques, as well as learn new things about yourself. You can find useful information about how meditation affects your well-being and emotions, and learn more about how you can use meditation to grow. Heal your soul and mind with our meditations!",
        RegistrationComplete: "Registration complete!",
        PasswordsMismatch: "Passwords dont match!",
        PasswordInvalid: "Invalid password: password must be 8 - 24 symbols long and must include both letters and numbers!",
        UsernameInvalid: "Invalid username: username must be 3 - 23 symbols long, can have only letters and numbers and must start with a letter!",
        UsernameTaken: "Username or email taken",
        NoServerResponse: "No Server Response",
        RegistrationFailed: "Registration Failed",
        Name: "Name",
        Email: "Email",
        SaveChanges: "Save Changes",
        LogIn: "Log In",
        SignUp: "Sign Up",
        Password: "Password",
        RepeatPassword: "Repeat Password",
        AuthenticationFailed: "Authentication Failed",
        Nicetoseeyouagain: "Nice to see you again!",
        Profile: "Profile",
        TemporarilyUnavailable: "Temporarily unavailable",
        BonusAccount: "Bonus account",
        Premium: "Premium",
        active: "active",
        until: "until",
        inactive: "inactive",
        PremiumSubscription: "Premium Subscription",
        PremiumDesc1: "Full access to Matrix of Destiny.",
        PremiumDesc2: "Full access to Taro Cards.",
        PremiumDesc3: "No ads.",
        PremiumDesc4: "Cancel anytime.",
        month: "month",
        PremiumCancelationProcess: "Contact us if you'd like to cancel your subscription",
        BuyPremium: "Buy Premium",
        PremiumRequired: "You need premium account to view more information about your Destiny Matrix",
        Calculating: "Calculating your Matrix of Destiny. This can take up to 30 seconds. Please wait...",
        CalculatingTarot: "Calculating your Tarot result. This can take up to 30 seconds. Please wait...",
        Limited: "You hit daily requests limit. Please, try again later or subscribe to our Premium plan.",
        LimitedTarot: "You hit daily requests limit. Please, try again later or subscribe to our Premium plan.",
        typequestion: "Write your question here",
        AskCards: "Ask cards",
        TermsTitle: "Terms of Use & Cookie Policy",
        TermsText1: "This website uses cookies to improve your experience. By using this site, you agree to our use of cookies, Terms of Use and Privacy Policy. Please read our",
        and: "and",
        TermsText2: "for more information.",
        Terms: "Terms Of Use",
        Privacy: "Privacy Policy",
        Accept: "Accept",
        Renewal: "Automatic subscription renewal",
    },
    ru: {
        hello: "Привет",
        Welcome: "Добро пожаловать",
        Home: "Главная",
        Matrix: "Матрица",
        Taro: "Таро",
        Chakras: "Чакры",
        Blog: "Блог",
        Contact: "Контакты",
        MatrixHeader: "Онлайн Калькулятор Матрицы Судьбы",
        MatrixofDestiny: "Матрица Судьбы",
        Meditations: "Медитации",
        name: "имя",
        dateofbirth: "дата рождения",
        malefemale: "мужской/женский",
        dmy: "день.месяц.год",
        Calculate: "Рассчитать",
        Learn: "Узнать больше",
        Listen: "Научиться",
        YourDestinyMatrix: "Ваша Матрица Судьбы",
        PersonalPurpose: "Личностное Предназначение",
        SocialPurpose: "Социальное Предназначение",
        SpiritualPurpose: "Духовное Предназначение",
        upto40: "до 40 лет",
        after40: "после 40 лет",
        after60: "после 60 лет",
        Sky: "Небо",
        Earth: "Земля",
        personsGoal: "Комбинируя Духовные и Материальные цели человека, мы получаем Личностные.",
        personsGoal2: "То полезное, что мы приносим в мир.",
        personsGoal3: "Если вы работали над Личностным и Социальным, то Духовное дается бонусом.",
        resultFor: "Результат для",
        guests: "гостей",
        BusinessCard: "Духовная карта",
        PastKarma: "Карма прошлой инкарнации",
        SoulStudy: "Главное учение души",
        TaskUntil40: "Предназначение души до 40 лет",
        Money: "Деньги",
        Relationship: "Отношения",
        MentalComfort: "Точка ментального комфорта",
        GenericTasks: "Общие задачи",
        Talents: "Таланты",
        Sexuality: "Сексуальность",
        MainPageText1: "Наш сайт – ваша дверь в мир возможностей. Мы используем уникальный алгоритм, который не только помогает вам лучше понять себя, но и предоставляет подробные прогнозы и советы для эффективного достижения успеха в различных сферах жизни. Если вы ищете способы вывести свою жизнь на уровень, которому вы всегда завидовали, то у нас есть для вас все инструменты и возможности. Давайте начнем приключение с Матрицы Судьбы!",
        MainPageText2: "Разгадайте тайны вашего прошлого, настоящего и будущего с помощью наших карт Таро! Наш сайт работает круглосуточно, без перерывов на обед и праздники. У нас всегда наготове полезная информация о картах, а также о том, как их правильно разместить для максимальной точности. Мы можем помочь вам раскрыть самые мрачные тайны, предсказать будущее и найти ответы на самые сложные вопросы.",
        MainPageText3: "На нашем сайте вы можете научиться практиковать медитацию и изучить различные техники, а также узнать о себе новое. Вы можете найти полезную информацию о том, как медитация влияет на ваше самочувствие и эмоции, а также узнать больше о том, как можно использовать медитацию для роста. Исцелите свою душу и разум нашими медитациями!",
        RegistrationComplete: "Регистрация завершена!",
        PasswordsMismatch: "Пароли не совпадают!",
        PasswordInvalid: "Неверный пароль: пароль должен состоять из 8–24 символов и содержать как буквы, так и цифры!",
        UsernameInvalid: "Неверное имя пользователя: имя пользователя должно иметь длину от 3 до 23 символов, может состоять только из букв и цифр и должно начинаться с буквы!",
        UsernameTaken: "Имя или почта занята",
        NoServerResponse: "Сервер не отвечает",
        RegistrationFailed: "Ошибка регистрации",
        Name: "Имя",
        Email: "Эл.почта",
        SaveChanges: "Сохранить",
        LogIn: "Войти",
        SignUp: "Регистрация",
        Password: "Пароль",
        RepeatPassword: "Повторите пароль",
        AuthenticationFailed: "Ошибка входа",
        Nicetoseeyouagain: "Приятно снова вас видеть!",
        Profile: "Профиль",
        TemporarilyUnavailable: "Временно недоступно",
        BonusAccount: "Бонусный счёт",
        Premium: "Премиум",
        active: "активен",
        until: "до",
        inactive: "неактивен",
        PremiumSubscription: "Премиум подписка",
        PremiumDesc1: "Полный доступ к Матрице Судьбы.",
        PremiumDesc2: "Полный доступ к Картам Таро.",
        PremiumDesc3: "Отключить всю рекламу.",
        PremiumDesc4: "Возможность отменить подписку в любое время.",
        month: "месяц",
        PremiumCancelationProcess: "Если вы хотите отменить подписку, напишите нам в службу поддержки",
        BuyPremium: "Купить Премиум",
        PremiumRequired: "Вам нужен премиум аккаунт, чтобы просмотреть больше информации по вашей Матрице Судьбы",
        Calculating: "Идёт расчёт вашей Матрицы Судьбы. Это может занять до 30 секунд. Пожалуйста, подождите...",
        CalculatingTarot: "Идёт расчёт расклада ваших Карт Таро. Это может занять до 30 секунд. Пожалуйста, подождите...",
        Limited: "Вы превысили дневной лимит расшифровок Матрицы Судьбы. Купите Премиум или попробуйте позже.",
        LimitedTarot: "Вы превысили дневной лимит раскладов Таро. Купите Премиум или попробуйте позже.",
        typequestion: "Задайте свой вопрос",
        AskCards: "Спросить карты",
        TermsTitle: "Условия пользования & Файлы куки",
        TermsText1: "Этот сайт использует куки файлы для лучшего опыта. Используя этот сайт, вы соглашаетесь с Условиями пользования, Политикой конфиденциальности и Политикой файлов куки. Пожалуйста, прочитайте",
        and: "и",
        TermsText2: "чтоб узнать больше.",
        Terms: "Условия пользования",
        Privacy: "Политику конфиденциальности",
        Accept: "Принять",
        Renewal: "Автоматическое продление подписки",
    }
};

// Create a provider component
export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en');

    const changeLanguage = (lang) => {
        setLanguage(lang);
    };

    const trans = (key) => {
        return translations[language][key] || key;
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage, trans }}>
            {children}
        </LanguageContext.Provider>
    );
};

// Create a custom hook to use the language context
export const useLanguage = () => {
    return useContext(LanguageContext);
};