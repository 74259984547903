import { React, useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, NavLink as Link } from "react-router-dom";
import "./auth.css";
import axios from '../api/axios';
import AuthContext from "../context/AuthProvider";
import { useLanguage } from '../context/LanguageContext';

//
const USER_REGEX = /^[a-zA-Zа-яА-Я][a-zA-Zа-яА-Я0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[0-9]).{8,24}$/;
const SIGNUP_URL = "/register";

const SignUp = (props) => {

  const userRef = useRef();
  const errRef = useRef();

  const { auth } = useContext(AuthContext);
  const { language, changeLanguage, trans } = useLanguage();

  const [user, setUser] = useState('');
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState('');


  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.body.style.overflow = 'auto';
    document.body.style.overflowX = 'hidden';
  };

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);

  useEffect(() => {
    const result = USER_REGEX.test(user);
    setValidName(result);
  }, [user]);

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);

  useEffect(() => {
    if (user !== '' || pwd !== '' || matchPwd !== '' || email !== '') {
      setErrMsg('');
    }
  }, [user, pwd, matchPwd, email]);

  const [backendData, setBackendData] = useState([{}]);

  useEffect(() => {
    fetch("/api").then(
      response => response.json()
    ).then(
      data => {
        setBackendData(data)
      }
    )

  }, [])

  const submitHandler = async (data) => {
    data = { user: data[0], pwd: data[1] };
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2 || pwd !== matchPwd) {
      if (!v1) {
        setErrMsg(trans('UsernameInvalid'));
        setSuccess(false);
      } else if (!v2) {
        setErrMsg(trans('PasswordInvalid'));
        setSuccess(false);
      } else {
        setErrMsg(trans('PasswordsMismatch'));
        setSuccess(false);
      }
      return;
    }
    try {
      let promo = window.location.pathname.substring(1).split('/')[1];
      const response = await axios.post(SIGNUP_URL,
        JSON.stringify({ user, pwd, email, promo }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      console.log(response?.data);
      console.log(response?.accessToken);
      console.log(JSON.stringify(response));
      setUser('');
      setPwd('');
      setMatchPwd('');
      setEmail('');
      setErrMsg(trans('RegistrationComplete'));
      setSuccess(true);
      document.getElementById('repeatInput').value = '';
      document.getElementById('passwordInput').value = '';
      document.getElementById('username').value = '';
      document.getElementById('emailInput').value = '';

    } catch (err) {
      if (!err?.response) {
        setErrMsg(trans('NoServerResponse'));
        setSuccess(false);
      } else if (err.response?.status === 409) {
        setErrMsg(trans('UsernameTaken'));
        setSuccess(false);
      } else {
        setErrMsg(trans('RegistrationFailed'));
        setSuccess(false);
      }
      errRef.current.focus();
    }
    /*
    fetch("/register", {method: 'POST',
    headers:{
    'Content-Type':'application/json'
    },
    body: JSON.stringify(data)})
    */
  }

  const editHandler = async (data) => {
    data = { user: data[0], pwd: data[1] };
    if(!user && !pwd){
      return(0);
    }
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2 || pwd !== matchPwd) {
      if (!v1) {
        setErrMsg(trans('UsernameInvalid'));
        setSuccess(false);
      } else if (!v2) {
        setErrMsg(trans('PasswordInvalid'));
        setSuccess(false);
      } else {
        setErrMsg(trans('PasswordMismatch'));
        setSuccess(false);
      }
      return;
    }
    try {
      return;
      const response = await axios.post(SIGNUP_URL,
        JSON.stringify({ user, pwd, email }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      //console.log(response?.data);
      //console.log(response?.accessToken);
      //console.log(JSON.stringify(response));
      setUser('');
      setPwd('');
      setMatchPwd('');
      setEmail('');
      setErrMsg('Registration complete!');
      setSuccess(true);
      document.getElementById('repeatInput').value = '';
      document.getElementById('passwordInput').value = '';
      document.getElementById('username').value = '';
      document.getElementById('emailInput').value = '';

    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
        setSuccess(false);
      } else if (err.response?.status === 409) {
        setErrMsg('Username Taken');
        setSuccess(false);
      } else {
        setErrMsg('Registration Failed');
        setSuccess(false);
      }
      errRef.current.focus();
    }
    /*
    fetch("/register", {method: 'POST',
    headers:{
    'Content-Type':'application/json'
    },
    body: JSON.stringify(data)})
    */
  }

  let navigate = useNavigate();
  const routeChangeToggle = () => {
    let path = "../login";
    navigate(path);
  }
  if (props.profile) {
    return (
      <>
      <form className='editForm'>
        <p ref={errRef} className={errMsg ? success ? "success" : "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        <p className='textProfileField' style={{fontSize: "1.2em", marginTop: "0.7em", marginBottom:"0", marginLeft: "15%"}}>{trans('Name')}</p>
        <input
          id='username'
          className='InputFieldProfile'
          placeholder={auth?.user}
          type='text'
          ref={userRef}
          autoComplete="off"
          required
          aria-invalid={validName ? "false" : "true"}
          aria-describedby="uidnote"
          onFocus={() => setUserFocus(true)}
          onBlur={() => setUserFocus(false)}
          onChange={(event) => { setUser(event.target.value) }}
        ></input>
        <p className='textProfileField' style={{fontSize: "1.2em", marginTop: "0.7em", marginBottom:"0", marginLeft: "15%"}}>{trans('Email')}</p>
        <input id='emailInput'
          className='InputFieldProfile'
          placeholder={auth?.email}
          type='text'
          ref={userRef}
          autoComplete="off"
          required
          onChange={(event) => { setEmail(event.target.value) }}></input>
        <input id='passwordInput' className='InputFieldProfile' type='password' onChange={(event) => { setPwd(event.target.value) }}
          required
          placeholder='Password'
          aria-invalid={validName ? "false" : "true"}
          aria-describedby="pwdnote"
          onFocus={() => setUserFocus(true)}
          onBlur={() => setUserFocus(false)}></input>
        <input id='repeatInput' className='InputFieldProfile'
          placeholder='Repeat Password'
          type='password'
          onChange={(event) => { setMatchPwd(event.target.value) }}
          required
          aria-invalid={validName ? "false" : "true"}
          aria-describedby="confirmnote"
          onFocus={() => setMatchFocus(true)}
          onBlur={() => setMatchFocus(false)}></input>
        <div className='editProfileButton seventhFont' onClick={() => { editHandler([user, pwd]) }}>{trans('SaveChanges')}</div>
      </form>
      <Link className="premiumLinkProfileButton" to="/profile/tariff">{trans('BuyPremium')}</Link>
      </>
    );
  } else {
    return (
      <div className='mainAuthContainer'>
        <form className='SignupForm'>
          <div className='seventhFont profileFS7' style={{ fontSize: "4vw", textAlign: "center" }}>{trans('Welcome')}</div>
          <div className='authSwitchContainer'>
            <div className='authSwitchItem' onClick={routeChangeToggle}>{trans('LogIn')}</div>
            <div className='authSwitchItem' style={{ backgroundColor: "#4D4DC2" }}>{trans('SignUp')}</div>
          </div>
          <p ref={errRef} className={errMsg ? success ? "success" : "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <input
            id='username'
            className='InputFieldAuth'
            placeholder={trans('Name')}
            type='text'
            ref={userRef}
            autoComplete="off"
            required
            aria-invalid={validName ? "false" : "true"}
            aria-describedby="uidnote"
            onFocus={() => setUserFocus(true)}
            onBlur={() => setUserFocus(false)}
            onChange={(event) => { setUser(event.target.value) }}
          ></input>
          <input id='emailInput'
            className='InputFieldAuth'
            placeholder={trans('Email')}
            type='text'
            ref={userRef}
            autoComplete="off"
            required
            onChange={(event) => { setEmail(event.target.value) }}></input>
          <input id='passwordInput' className='InputFieldAuth' type='password' onChange={(event) => { setPwd(event.target.value) }}
            required
            placeholder={trans('Password')}
            aria-invalid={validName ? "false" : "true"}
            aria-describedby="pwdnote"
            onFocus={() => setUserFocus(true)}
            onBlur={() => setUserFocus(false)}></input>
          <input id='repeatInput' className='InputFieldAuth'
            placeholder={trans('RepeatPassword')}
            type='password'
            onChange={(event) => { setMatchPwd(event.target.value) }}
            required
            aria-invalid={validName ? "false" : "true"}
            aria-describedby="confirmnote"
            onFocus={() => setMatchFocus(true)}
            onBlur={() => setMatchFocus(false)}></input>
          <div className='SubmitButton seventhFont' onClick={() => { submitHandler([user, pwd]) }}>{trans('SignUp')}</div>
        </form>
      </div>
    );
  }
};

export default SignUp;