import React, { useContext, useEffect, useState, useRef } from "react";
import "./matrix.css";
import "./styles.css";
import axios from '../api/axios';
import { getFateMatrix } from "../utils/fateMatrix";
import { Queue } from "../utils/util";
import MatrixDiagram from "../components/matrix/matrixDiagram";
import MatrixTable from "../components/matrix/matrixTable";
import icon145 from "./resources/icon145.png";
import matrixSvgImg from "../components/matrix/Matrix3.svg";
import AuthContext from "../context/AuthProvider";
import { useLanguage } from '../context/LanguageContext';
import useWindowDimensions from '../utils/windowDimensions';
import { useNavigate, NavLink as Link } from "react-router-dom";

import taroTest from "./resources/test-taro.png";
import cardBack from "./resources/cardBack.png";
import fool from "./resources/fool.png";
import magician from "./resources/magician.png";
import highpriestess from "./resources/highpriestess.png";
import empress from "./resources/empress.png";
import emperor from "./resources/emperor.png";
import hierophant from "./resources/hierophant.png";
import lovers from "./resources/lovers.png";
import chariot from "./resources/chariot.png";
import strength from "./resources/strength.png";
import hermit from "./resources/hermit.png";
import fortune from "./resources/fortune.png";
import justice from "./resources/justice.png";
import hanged from "./resources/hanged.png";
import death from "./resources/death.png";
import temperance from "./resources/temperance.png";
import devil from "./resources/devil.png";
import tower from "./resources/tower.png";
import star from "./resources/star.png";
import moon from "./resources/moon.png";
import sun from "./resources/sun.png";
import judgement from "./resources/judgement.png";
import world from "./resources/world.png";


var cards = [];
var animQueue = [];
var chosenCards = [];
var cardList = [
  ['The Fool', fool],
  ['The Magician', magician],
  ['The High Priestess', highpriestess],
  ['The Empress', empress],
  ['The Emperor', emperor],
  ['The Hierophant', hierophant],
  ['The Lovers', lovers],
  ['The Chariot', chariot],
  ['Strength', strength],
  ['The Hermit', hermit],
  ['Wheel of Fortune', fortune],
  ['Justice', justice],
  ['The Hanged Man', hanged],
  ['Death', death],
  ['Temperance', temperance],
  ['The Devil', devil],
  ['The Tower', tower],
  ['The Star', star],
  ['The Moon', moon],
  ['The Sun', sun],
  ['Judgement', judgement],
  ['The World', world],
]
var flipped = 0;
var animationId = null;
const backImage = new Image();
backImage.src = cardBack;

const Taro = () => {

    cards = [];
    animQueue = [];
    chosenCards = [];

    const { auth, setAuth } = useContext(AuthContext);
    const { backendUrl } = useContext(AuthContext);
    const { language, changeLanguage, trans } = useLanguage();
    const { height, width } = useWindowDimensions();
    let navigate = useNavigate();

    const [ question, setQuestion ] = useState('');
    const [ answer, setAnswer ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ loggedIn, setLoggedIn ] = useState(false);
    const [ limited, setLimited ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const progressRef = useRef(null);
    const loadProgress = useRef(0);

    const canvasRef = useRef(null);

    const TARO_URL = '/taro';
    const TAROPROTECTED_URL = '/taroProtected';

    const DEGREES = Math.PI*2/360;
    var CANVAS_WIDTH = 3000;
    var CANVAS_HEIGHT = 3000;
    const WIDTH = 300;
    const HEIGHT = 400;
    var FPS = 60;
    var interval = 1000 / FPS;
    var SCALE = 2;
    var OFFSET;
    var fps;
    var ctx;

    if(width > 500){
      SCALE = SCALE * 900 / width;
    } else {
      FPS = 60;
      interval = 1000 / FPS;
    }

    window.cancelRequestAnimFrame = ( function() {
      return window.cancelAnimationFrame          ||
          window.webkitCancelRequestAnimationFrame    ||
          window.mozCancelRequestAnimationFrame       ||
          window.oCancelRequestAnimationFrame     ||
          window.msCancelRequestAnimationFrame        ||
          clearTimeout
    } )();
    

    class Anim {
      index = 0;
      frame = 0;
      endFrame = 0;
      startValue = 0;
      value = 0;
      param = '';
      isOver = false;
      nextAnim = null;
      func = null;
      constructor(index, duration, param, value=null) {
        if(param === 'scaleX'){
          this.param = param;
          if(cards[index][param] === -1){
            this.value = 2;
          } else {
            this.value = -2;
          }
        } else if(param === 'x' || param === 'y' || param === 'scale'){
          this.value = value * SCALE;
        } else {
          this.value = value;
        }
        this.index = index;
        this.endFrame = duration * FPS;
        this.param = param;
        this.startValue = cards[this.index][this.param];
      }
      async run(){
        if(this.frame === 0){
          this.startValue = cards[this.index][this.param];
        }
        if(this.frame < this.endFrame){
          cards[this.index][this.param] = this.startValue + this.value * (this.frame / this.endFrame);
          this.frame += 1;
        } else if(!this.isOver){
          cards[this.index][this.param] = this.startValue + this.value;
          this.isOver = true;
          if(this.nextAnim){
            animQueue.push(this.nextAnim);
          }
          if(this.func){
            await this.func();
          }
        }
      }
      next(anim){
        this.nextAnim = anim;
      }
      then(func){
        this.func = func;
      }
    }

    class Card {
      name = 'The Fool';
      x = 0;
      y = 0;
      img = null;
      angle = 0;
      scaleX = -1;
      scale = SCALE;
      isChosen = false;
      isFlipped = false;
      constructor(x, y, image, name, angle) {
        this.x = x * SCALE;
        this.y = y * SCALE;
        this.img = image;
        this.name = name;
        this.angle = angle;
      }
      drawCard(){
        ctx.save();
        ctx.translate(this.x, this.y);
        ctx.rotate(this.angle * DEGREES);
        ctx.scale(this.scale * Math.abs(this.scaleX), this.scale);
        if(this.scaleX > 0){
          ctx.drawImage(this.img, -WIDTH / 2, -HEIGHT / 2, WIDTH, HEIGHT);
        } else {
          ctx.drawImage(backImage, -WIDTH / 2, -HEIGHT / 2, WIDTH, HEIGHT);
        }
        ctx.restore();
      }
      isWithin(x, y) {
        var translatedX = x - this.x;
        const translatedY = y - this.y;
        translatedX -= OFFSET;
        const cos = Math.cos(-this.angle * DEGREES);
        const sin = Math.sin(-this.angle * DEGREES);
        const rotatedX = translatedX * cos - translatedY * sin;
        const rotatedY = translatedX * sin + translatedY * cos;
        
        const scaledX = rotatedX / this.scale;
        const scaledY = rotatedY / (this.scale * this.scaleX);
        
        return scaledX >= -WIDTH/2 && scaledX <= WIDTH/2 && scaledY >= -HEIGHT/2 && scaledY <= HEIGHT/2;
      }
    }

    useEffect(() => {
      if (question !== "") {
        document.getElementById("firstPage").style.display = "none";
        document.getElementById("secondPage").style.display = "";
        document.getElementById("matrixPage").style.position = "static";
      } else {
        document.getElementById("firstPage").style.display = "";
        document.getElementById("secondPage").style.display = "none";
        document.getElementById("matrixPage").style.position = "fixed";
        for (var i = 1; i < 99999; i++) {
          window.clearInterval(i);
          window.cancelRequestAnimFrame(i);
        }
      }
    }, [question]);

    useEffect(() => {

      if (question !== "" && loading == false) {
        chosenCards = [];
        animQueue = [];
        cards = []
        flipped = 0;
        const canvas = canvasRef.current;
        ctx = canvas.getContext('2d');

        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const rect = canvas.getBoundingClientRect();
        const rectCenter = (rect.right - rect.left)/2;
        OFFSET = rectCenter * centerX/(rectCenter);
        ctx.translate(OFFSET, 0);

        let loadedImgs = 0;
        /*
        loadProgress.current = (loadedImgs / 22) * 100;
        if (progressRef.current) {
          if(loadProgress.current == 100){
            progressRef.current.style.display = "none";
          }
          progressRef.current.style.width = `${loadProgress.current}%`;
          progressRef.current.innerText = `${Math.round(loadProgress.current)}%`;
        }*/

        var image;
        if (loadedImgs === 0) {
          if (progressRef.current) {
            if (loadProgress.current == 100) {
              progressRef.current.style.display = "none";
            }
            progressRef.current.style.width = `${loadProgress.current}%`;
            progressRef.current.innerText = `${Math.round(loadProgress.current)}%`;
          }
        }
        const loadImage = (index) => {
          var image = new Image();
          const handleImageLoad = () => {
            loadedImgs += 1;
            cardList[index][1] = image;
            loadProgress.current = (loadedImgs / 22) * 100;
            
            if (progressRef.current) {
              if (loadProgress.current == 100) {
                progressRef.current.style.display = "none";
              }
              progressRef.current.style.width = `${loadProgress.current}%`;
              progressRef.current.innerText = `${Math.round(loadProgress.current)}%`;
            }
        
            if (loadedImgs === 22) {
              setupCards(image);
            }
          };
          if(typeof(cardList[index][1]) == 'string'){
            image.src = cardList[index][1];
          } else {
            image = cardList[index][1];
            handleImageLoad();
          }
          image.onload = handleImageLoad;
        };
  
        for (let i = 0; i < 22; i++) {
          loadImage(i);
        }
      } else if(question !== '' && loading == true){
        const canvas = canvasRef.current;
        ctx = canvas.getContext('2d');
        ctx.translate(20000, 0);
      }
    }, [question, loading]);

    const setupCards = (image) => {
      cards = [
        new Card(-189, 270, image, 'The Fool', 30),
        new Card(-126, 280, image, 'The Fool', 20),
        new Card(-63, 290, image, 'The Fool', 10),
        new Card(0, 300, image, 'The Fool', 0),
        new Card(63, 290, image, 'The Fool', -10),
        new Card(126, 280, image, 'The Fool', -20),
        new Card(189, 270, image, 'The Fool', -30)
      ];
  
      for (let i = 0; i < 7; i++) {
        let unique = false;
        let x = cardList[Math.floor(Math.random() * cardList.length)];
        for (let card of cards) {
          if (card.name === x[0]) {
            i -= 1;
            break;
          }
          unique = true;
        }
        if(unique){
          cards[i].name = x[0];
          cards[i].img = x[1];
        }
      }
      runFrame();
    };

    function runFrame(){
      if(!loading){
        ctx.clearRect(-CANVAS_WIDTH/2, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
        for(let anim of animQueue){
          anim.run();
        }
        for(let card of cards){
          card.drawCard();
        }
        setTimeout(() => {
            requestAnimationFrame(runFrame);
        }, interval);
      }
    }

    function handleClick(event){
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const x = (event.clientX - rect.left) * (canvas.width / rect.width);
      const y = (event.clientY - rect.top) * (canvas.height / rect.height);
      for(let i = cards.length - 1; i >= 0; i--){
        let card = cards[i];
        if(card.isWithin(x, y)){
          if(card.isChosen === false){
            if(chosenCards.length === 0){
              animQueue.push(new Anim(i, 0.5, 'x', -400 - card.x/SCALE));
              animQueue.push(new Anim(i, 0.5, 'y', 600));
              animQueue.push(new Anim(i, 0.5, 'angle', 360 - card.angle + (Math.random()-0.5) * 10));
              chosenCards.push(card.name);
              card.isChosen = true;
            } else if(chosenCards.length === 1){
              animQueue.push(new Anim(i, 0.5, 'x', 0 - card.x/SCALE));
              animQueue.push(new Anim(i, 0.5, 'y', 600));
              animQueue.push(new Anim(i, 0.5, 'angle', 360 - card.angle + (Math.random()-0.5) * 10));
              chosenCards.push(card.name);
              card.isChosen = true;
            } else if(chosenCards.length === 2){
              animQueue.push(new Anim(i, 0.5, 'x', 400 - card.x/SCALE));
              animQueue.push(new Anim(i, 0.5, 'y', 600));
              animQueue.push(new Anim(i, 0.5, 'angle', 360 - card.angle + (Math.random()-0.5) * 10));
              chosenCards.push(card.name);
              card.isChosen = true;
            }
            break;
          } else if(card.isFlipped === false){
            flipped += 1;
            let anim1 = new Anim(i, 0.2, 'scale', 0.15);
            let anim2 = new Anim(i, 0.4, 'scaleX', null);
            let anim3 = new Anim(i, 0.2, 'scale', -0.15);
            if(flipped == 3){
              anim3.then(askTaro);
            }
            anim1.next(anim2);
            anim2.next(anim3);
            animQueue.push(anim1);
            card.isFlipped = true;
          }
        }
      }
    }

    async function handleButton(event){
      let x = document.getElementById("questionInput").value;
      if(x.length < 3){
        return;
      }
      setQuestion(x);
    }

    async function askTaro(stop=false){
      let data = chosenCards;
      data[3] = question;
      try {
        setLoading(true);
        let response;
        console.log(auth);
        if(auth && auth.user && auth.accessToken){
          response = await axios.post(TAROPROTECTED_URL,
            JSON.stringify({ "user": auth.user, "token": auth.accessToken, data, "lang": language }),
            {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth.accessToken}` },
                withCredentials: true, 
                timeout: 27000
            }
          );
          response = response.data.data;
          setLoggedIn(true);
          setLimited(false);
        } else {
          response = await axios.post(TARO_URL,
            JSON.stringify({ data, "lang": language }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true, 
                timeout: 27000
            }
          );
          response = response.data.data;
          setLoggedIn(false);
          setLimited(false);
        }
        
        setAnswer(response);
      } catch (error) {
        if (error.response && error.response.status === 429) {
          setLimited(true);
        } else if(error.response && error.response.status === 403) {
          setLimited(false);
          setAuth(null);
          localStorage.setItem("auth", JSON.stringify(null));
          //navigate('/login');
          if(!stop){
            await askTaro(stop=true);
          }
        } else {
          console.log(error.message);
        }
      }
    }

    return (
      <div id="matrixPage" className="mainMatrixContainer">
        <div id="firstPage">
          <span className="headerFont mainMatrixHeader">{trans('Taro')}</span>
          <span className="secondFont blockStyle">{trans('typequestion')}</span>
          <input className="inputField" id="questionInput" type="text"></input>
          <button className="buttonStyle" onClick={(e) => { handleButton(e) }}>{trans('AskCards')}</button>
        </div>
        <div id="secondPage" style={{color: "aliceblue"}}>
          { loadProgress.current < 99 ?
            <div className="progress-container">
              <div className="progress-bar" ref={progressRef}></div>
            </div>
            :
            <></>
          }
          <canvas style={{width: "97%"}} id="canvas" width="3000" height="3000" ref={canvasRef} onClick={(e) => {handleClick(e)}}></canvas>
          { answer ? 
            <>
              <div className="TaroTextContainer">
                {loggedIn ? <div className="forthFont matrixForUser">{trans('resultFor')} {auth.user}</div> : <div className="forthFont matrixForUser">{trans('resultFor')} {trans('guests')}</div>}
                <span className="headerFont matrixForUser">{question}</span>
                <div className="forthFont descriptionText" style={{color: "aliceblue"}}>{answer}</div>
              </div>
            </>
            :
            limited ?
              <div>{trans('LimitedTarot')}</div>
              :
              loading ?
              <span className="headerFont">{trans('CalculatingTarot')}</span>
              :
              <></>
          }
        </div>
      </div>
    );
}

export default Taro;