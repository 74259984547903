import React, { useContext, useEffect, useState, useRef } from "react";
import "./matrixTable.css";
import "../../pages/styles.css";
import VectorPurple from "./resources/VectorPurple.svg";
import VectorBlue from "./resources/VectorBlue.svg";
import VectorLightblue from "./resources/VectorLightblue.svg";
import VectorGreen from "./resources/VectorGreen.svg";
import VectorYellow from "./resources/VectorYellow.svg";
import VectorOrange from "./resources/VectorOrange.svg";
import VectorRed from "./resources/VectorRed.svg";


const MatrixTable = (props) => {
    
    const data = props.data;
    const name = props.name;
    const date = props.date;

    function getArcane(x, y){
        let element = parseInt(x, 10) + parseInt(y, 10);
        if(element > 22){
            element = String(parseInt(String(element).split('')[0], 10) + parseInt(String(element).split('')[1], 10));
        }
        return(element);
    }

    const drawData = () => {
        return(
            <>
            <div className="MediumNumberT" id="idt0">{data[0]}</div>
            <div className="MediumNumberT" id="idt1">{data[1]}</div>
            <div className="MediumNumberT" id="idt2">{data[2]}</div>
            <div className="MediumNumberT" id="idt3">{data[3]}</div>
            <div className="MediumNumberT" id="idt4">{data[4]}</div>
            <div className="MediumNumberT" id="idt13">{data[13]}</div>
            <div className="MediumNumberT" id="idt19">{data[19]}</div>
            <div className="MediumNumberT" id="idt16">{data[16]}</div>
            <div className="MediumNumberT" id="idt22">{data[22]}</div>
            <div className="MediumNumberT" id="idt10">{data[10]}</div>
            <div className="MediumNumberT" id="idt15">{data[15]}</div>
            <div className="MediumNumberT" id="idt8">{data[8]}</div>
            <div className="MediumNumberT" id="idt21">{data[21]}</div>
            <div className="MediumNumberT" id="idt12">{data[12]}</div>
            <div className="MediumNumberT" id="idt18">{data[18]}</div>
            <div className="MediumNumberT" id="idt6">{data[6]}</div>
            <div className="MediumNumberT" id="idt24">{data[24]}</div>
            <div className="MediumNumberT" id="idt9">{data[9]}</div>
            <div className="MediumNumberT" id="idt14">{data[14]}</div>
            <div className="MediumNumberT" id="idt7">{data[7]}</div>
            <div className="MediumNumberT" id="idt20">{data[20]}</div>
            <div className="MediumNumberT" id="idt11">{data[11]}</div>
            <div className="MediumNumberT" id="idt17">{data[17]}</div>
            <div className="MediumNumberT" id="idt5">{data[5]}</div>
            <div className="MediumNumberT" id="idt23">{data[23]}</div>
            <div className="MediumNumberT" id="idt26">{data[26]}</div>
            <div className="MediumNumberT" id="idt25">{data[25]}</div>
            <div className="MediumNumberT" id="idt28">{data[28]}</div>
            <div className="MediumNumberT" id="idt27">{data[27]}</div>
            <div className="MediumNumberT" id="idt29">{data[29]}</div>
            </>
        );
    }

    return (
        <div className="panelTable forthFont">
            <div className="tableUserName">
                <div className="tableUserNameText thirdFont">{name}</div>
            </div>
            <div className="tableDate">
                <div className="tableDateText">{date}</div>
            </div>
            <div className="tableChakrasContainer">
                <div className="tableChakra table7Chakra"></div>
                <div className="tableChakra table6Chakra"></div>
                <div className="tableChakra table5Chakra"></div>
                <div className="tableChakra table4Chakra"></div>
                <div className="tableChakra table3Chakra"></div>
                <div className="tableChakra table2Chakra"></div>
                <div className="tableChakra table1Chakra"></div>
            </div>

            
            <div className="MediumNumberT" style={{left: "1.5vw", top: "4.6vw"}}>№</div>
            <div className="matrixTextTstart" style={{left: "5vw", top: "4.6vw"}}>Chakra</div>
            <div className="MediumNumberT" style={{left: "15vw", top: "4.6vw"}}>Physics</div>
            <div className="MediumNumberT" style={{left: "23vw", top: "4.6vw"}}>Energy</div>
            <div className="MediumNumberT" style={{left: "30.5vw", top: "4.6vw"}}>Emotions</div>

            <div className="MediumNumberT table7ChakraColor" style={{left: "1.5vw", top: "7.8vw"}}>7</div>
            <div className="MediumNumberT table6ChakraColor" style={{left: "1.5vw", top: "10.6vw"}}>6</div>
            <div className="MediumNumberT table5ChakraColor" style={{left: "1.5vw", top: "13.4vw"}}>5</div>
            <div className="MediumNumberT table4ChakraColor" style={{left: "1.5vw", top: "16.2vw"}}>4</div>
            <div className="MediumNumberT table3ChakraColor" style={{left: "1.5vw", top: "19.0vw"}}>3</div>
            <div className="MediumNumberT table2ChakraColor" style={{left: "1.5vw", top: "21.8vw"}}>2</div>
            <div className="MediumNumberT table1ChakraColor" style={{left: "1.5vw", top: "24.6vw"}}>1</div>

            <div className="matrixTextTstart table7ChakraColor" style={{left: "5vw", top: "7.8vw"}}>Crown<img className="vectorColor" src={VectorPurple}></img></div>
            <div className="matrixTextTstart table6ChakraColor" style={{left: "5vw", top: "10.6vw"}}>Third Eye<img className="vectorColor" src={VectorBlue}></img></div>
            <div className="matrixTextTstart table5ChakraColor" style={{left: "5vw", top: "13.4vw"}}>Throat<img className="vectorColor" src={VectorLightblue}></img></div>
            <div className="matrixTextTstart table4ChakraColor" style={{left: "5vw", top: "16.2vw"}}>Heart<img className="vectorColor" src={VectorGreen}></img></div>
            <div className="matrixTextTstart table3ChakraColor" style={{left: "5vw", top: "19.0vw"}}>Solar Plexus<img className="vectorColor" src={VectorYellow}></img></div>
            <div className="matrixTextTstart table2ChakraColor" style={{left: "5vw", top: "21.8vw"}}>Sacral<img className="vectorColor" src={VectorOrange}></img></div>
            <div className="matrixTextTstart table1ChakraColor" style={{left: "5vw", top: "24.6vw"}}>Root<img className="vectorColor" src={VectorRed}></img></div>

            <div className="MediumNumberT table7ChakraColor" style={{left: "17vw", top: "7.8vw"}}>{data[0]}</div>
            <div className="MediumNumberT table6ChakraColor" style={{left: "17vw", top: "10.6vw"}}>{data[10]}</div>
            <div className="MediumNumberT table5ChakraColor" style={{left: "17vw", top: "13.4vw"}}>{data[9]}</div>
            <div className="MediumNumberT table4ChakraColor" style={{left: "17vw", top: "16.2vw"}}>{data[26]}</div>
            <div className="MediumNumberT table3ChakraColor" style={{left: "17vw", top: "19.0vw"}}>{data[4]}</div>
            <div className="MediumNumberT table2ChakraColor" style={{left: "17vw", top: "21.8vw"}}>{data[11]}</div>
            <div className="MediumNumberT table1ChakraColor" style={{left: "17vw", top: "24.6vw"}}>{data[2]}</div>

            <div className="MediumNumberT table7ChakraColor" style={{left: "25vw", top: "7.8vw"}}>{data[1]}</div>
            <div className="MediumNumberT table6ChakraColor" style={{left: "25vw", top: "10.6vw"}}>{data[8]}</div>
            <div className="MediumNumberT table5ChakraColor" style={{left: "25vw", top: "13.4vw"}}>{data[7]}</div>
            <div className="MediumNumberT table4ChakraColor" style={{left: "25vw", top: "16.2vw"}}>{data[25]}</div>
            <div className="MediumNumberT table3ChakraColor" style={{left: "25vw", top: "19.0vw"}}>{data[4]}</div>
            <div className="MediumNumberT table2ChakraColor" style={{left: "25vw", top: "21.8vw"}}>{data[5]}</div>
            <div className="MediumNumberT table1ChakraColor" style={{left: "25vw", top: "24.6vw"}}>{data[3]}</div>

            <div className="MediumNumberT table7ChakraColor" style={{left: "33vw", top: "7.8vw"}}>{getArcane(data[0],data[1])}</div>
            <div className="MediumNumberT table6ChakraColor" style={{left: "33vw", top: "10.6vw"}}>{getArcane(data[10],data[8])}</div>
            <div className="MediumNumberT table5ChakraColor" style={{left: "33vw", top: "13.4vw"}}>{getArcane(data[9],data[7])}</div>
            <div className="MediumNumberT table4ChakraColor" style={{left: "33vw", top: "16.2vw"}}>{getArcane(data[26],data[25])}</div>
            <div className="MediumNumberT table3ChakraColor" style={{left: "33vw", top: "19.0vw"}}>{getArcane(data[4],data[4])}</div>
            <div className="MediumNumberT table2ChakraColor" style={{left: "33vw", top: "21.8vw"}}>{getArcane(data[11],data[5])}</div>
            <div className="MediumNumberT table1ChakraColor" style={{left: "33vw", top: "24.6vw"}}>{getArcane(data[2],data[3])}</div>

            <div className="MediumNumberT" style={{left: "3vw", top: "27.4vw"}}>Total</div>
            <div className="MediumNumberT" style={{left: "17vw", top: "27.4vw"}}>
                {getArcane(data[0],getArcane(data[10],getArcane(data[9],getArcane(data[26],getArcane(data[4],getArcane(data[11],data[2]))))))}
            </div>
            <div className="MediumNumberT" style={{left: "25vw", top: "27.4vw"}}>
            {getArcane(data[1],getArcane(data[8],getArcane(data[7],getArcane(data[25],getArcane(data[4],getArcane(data[5],data[3]))))))}
            </div>
            <div className="MediumNumberT" style={{left: "33vw", top: "27.4vw"}}>
            {getArcane(getArcane(data[0],data[1]),getArcane(getArcane(data[10],data[8]),getArcane(getArcane(data[9],data[7]),getArcane(getArcane(data[26],data[25]),
            getArcane(getArcane(data[4],data[4]),getArcane(getArcane(data[11],data[5]),getArcane(data[2],data[3])))))))}
            </div>
        </div>
    );
};

export default MatrixTable;