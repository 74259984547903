import React, { useContext, useEffect, useState, useRef } from "react";
import matrixSvg from "./Matrix3.svg";
import "./matrixDiagram.css";

const MatrixDiagram = (props) => {
    
    const data = props.data;
    const decoration = props.decoration;

    const drawData = () => {
        return(
            <>
            <div className="BigNumber" id="id0">{data[0]}</div>
            <div className="BigNumber" id="id1">{data[1]}</div>
            <div className="BigNumber" id="id2">{data[2]}</div>
            <div className="BigNumber" id="id3">{data[3]}</div>
            <div className="BigNumber" id="id4">{data[4]}</div>
            <div className="BigNumber" id="id13">{data[13]}</div>
            <div className="BigNumber" id="id19">{data[19]}</div>
            <div className="BigNumber" id="id16">{data[16]}</div>
            <div className="BigNumber" id="id22">{data[22]}</div>
            <div className="MediumNumber" id="id10">{data[10]}</div>
            <div className="MediumNumber" id="id15">{data[15]}</div>
            <div className="MediumNumber" id="id8">{data[8]}</div>
            <div className="MediumNumber" id="id21">{data[21]}</div>
            <div className="MediumNumber" id="id12">{data[12]}</div>
            <div className="MediumNumber" id="id18">{data[18]}</div>
            <div className="MediumNumber" id="id6">{data[6]}</div>
            <div className="MediumNumber" id="id24">{data[24]}</div>
            <div className="SmallNumber" id="id9">{data[9]}</div>
            <div className="SmallNumber" id="id14">{data[14]}</div>
            <div className="SmallNumber" id="id7">{data[7]}</div>
            <div className="SmallNumber" id="id20">{data[20]}</div>
            <div className="SmallNumber" id="id11">{data[11]}</div>
            <div className="SmallNumber" id="id17">{data[17]}</div>
            <div className="SmallNumber" id="id5">{data[5]}</div>
            <div className="SmallNumber" id="id23">{data[23]}</div>
            <div className="SmallNumber" id="id26">{data[26]}</div>
            <div className="SmallNumber" id="id25">{data[25]}</div>
            <div className="SmallNumber" id="id28">{data[28]}</div>
            <div className="SmallNumber" id="id27">{data[27]}</div>
            <div className="SmallNumber" id="id29">{data[29]}</div>
            </>
        );
    }
    if(decoration){
        return (
            <div className="panel matrixImageFirstPage">
                <img className="matrixImage" src={matrixSvg}></img>
                {drawData()}
            </div>
        );
    }

    return (
        <div className="panel">
            <img className="matrixImage" src={matrixSvg}></img>
            {drawData()}
        </div>
    );
};

export default MatrixDiagram;