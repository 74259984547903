import { React, useRef, useState, useEffect, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import "./profile.css";
import axios from '../api/axios';
import SingUp from '../pages/signup.js';
import { useLocation } from 'react-router-dom';
import { NavLink as Link } from "react-router-dom";
import luckImg from './resources/luck_777.png';
import diamond from './resources/diamond.png';
import book from './resources/book-01.png';
import coinbroken from './resources/coin-broken.png';
import download from './resources/download-01.png';
import Iconheart from './resources/Iconheart.png';
import image423 from './resources/logout-02.png';
import userProfileImg from './resources/user-profile-03.png';
import DollarBanknote from './resources/DollarBanknote.png';
import ShoppingBags from './resources/ShoppingBags.png';
import Warning from './resources/Warning.png';
import WomanAndMan from './resources/WomanAndMan.png';
import defaultIcon from './resources/defaultIcon.png';
import { format } from 'date-fns';
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthProvider';


  
const Profile = () => {

    const { backendUrl } = useContext(AuthContext);
    const { auth, setAuth } = useAuth();
    const { language, changeLanguage, trans } = useLanguage();

    const [ path, setPath ] = useState('/profile/');
    const [ premium, setPremium ] = useState(null);
    const [ balance, setBalance ] = useState(0);

    const STRIPELINK_URL = 'createStripeLink';
    const CHECKPREMIUM_URL = 'checkPremium';

    const location = useLocation();
    const navigate = useNavigate();

    const disableScroll = () => {
        document.body.style.overflow = 'hidden';
    };

    const enableScroll = () => {
        document.body.style.overflow = 'auto';
        document.body.style.overflowX = 'hidden';
    };

    
    useEffect(() => {
        if(!auth?.user){
            setAuth(JSON.parse(localStorage.getItem("auth")));
        }
        if(!auth?.user && !JSON.parse(localStorage.getItem("auth"))){
            navigate('/login');
        }
        disableScroll();
        checkPremium();
        return () => {
            enableScroll();
        };
    }, [auth]);

    useEffect(() => {
        setPath(location.pathname);
        disableScroll();
    }, [location]);

    useEffect(() => {
        disableScroll();
    }, [path]);

    function handleAffiliateCheckbox(event){
        if(event.target.checked){
            document.getElementById('AffiliateButton').className = "affiliateButton active";
        } else {
            document.getElementById('AffiliateButton').className = "affiliateButton";
        }
    }

    const uploadProfileImg = async (event) => {
        return;
        try {
          let image = event.target.files[0];
          const formData = new FormData();
          formData.append('image', image, "image.png");
          const res = await axios.post(`/img/${auth.user}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          console.log(res);
          document.getElementById('profileImageId').src = URL.createObjectURL(image);
        } catch (err) {
          console.error(err);
        }
    }
    
    const imageExists = async (url) => {
        try {
          const response = await fetch(url, { method: 'HEAD' });
          if(response.status === 406){
            document.getElementById('profileImageId').src = defaultIcon;
            return;
            
          } else {
            document.getElementById('profileImageId').src = `${backendUrl}/img/${auth.user}`;
            return;
          }
        } catch (error) {
            if(document.getElementById('profileImageId')){
                document.getElementById('profileImageId').src = defaultIcon;
            }
            return;
        }
    };

    function exitAccount(event){
        setAuth(null);
        localStorage.setItem("auth", JSON.stringify(null));
        navigate("/login");
    }
    
    async function openStripeLink(event){
        try {
            let response = await axios.post(STRIPELINK_URL,
                JSON.stringify({ "user": auth.user, "token": auth.accessToken }),
                {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth.accessToken}` },
                    withCredentials: true, 
                    timeout: 3000
                }
            );
            if(response.data.url){
                const newWindow = window.open(response.data.url, '_blank', 'noopener,noreferrer');
                if (newWindow) newWindow.opener = null;
            }
        } catch (error) {
            
        }
    }
    async function checkPremium(){
        let response;
        try {
            response = await axios.post(CHECKPREMIUM_URL,
                JSON.stringify({ "user": auth.user, "token": auth.accessToken }),
                {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth.accessToken}` },
                    withCredentials: true, 
                    timeout: 3000
                }
            );
            if(response.status == 403){
                if(auth.user){
                    navigate('/login');
                }
            }
            if(response.data.premium){
                setPremium(new Date(response.data.premium));
            }
            if(response.data.balance){
                setBalance(response.data.balance);
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (auth.user) {
                    navigate('/login');
                }
            }
        }
    }

    return(
        <div className='mainProfileContainer'>
            <div className='mainProfileForm'>
                <div className='profileNavbar'>
                    <img style={{display:"block", width: "7em", marginInline: "auto", marginTop: "2em", zIndex: "-1"}} src={luckImg}></img>
                    <Link style={{marginTop: "1.4em"}} className="profileNavbarLink" to='/profile/'><img style={{width:"1.3em", position: "relative", top: "0.3em", marginRight: "0.3em"}} src={userProfileImg}/>Profile</Link>
                    <Link className="profileNavbarLink" to='/profile/affiliate'><img style={{width:"1.3em", position: "relative", top: "0.3em", marginRight: "0.3em"}} src={diamond}/>Affiliate program</Link>
                    <Link className="profileNavbarLink" to='/profile/bonusAcc'><img style={{width:"1.3em", position: "relative", top: "0.3em", marginRight: "0.3em"}} src={coinbroken}/>Bonus account</Link>
                    <Link className="profileNavbarLink" to='/profile/tariff'><img style={{width:"1.3em", position: "relative", top: "0.3em", marginRight: "0.3em"}} src={book}/>My tariff</Link>
                    <Link className="profileNavbarLink" to='/profile/materials'><img style={{width:"1.3em", position: "relative", top: "0.3em", marginRight: "0.3em"}} src={download}/>Materials</Link>
                    <Link className="profileNavbarLink" to='/profile/likes'><img style={{width:"1.3em", position: "relative", top: "0.3em", marginRight: "0.3em"}} src={Iconheart}/>Likes</Link>
                    <div className="profileNavbarLink" onClick={(e) => {exitAccount(e)}}><img style={{width:"1.3em", position: "relative", top: "0.3em", marginRight: "0.3em"}} src={image423}/>Exit</div>
                </div>
                <div className='profileContent'>
                    {path === '/profile/' ? 
                        <>
                            <div className='leftMargin05' style={{marginLeft:"3em", marginTop: "1em", fontSize: "2em"}}>{trans('Profile')}</div>
                            <div className='profileUserInfo leftMargin1'>
                                <label for="file-input-profile" className="profileImageContainer">
                                    <img id='profileImageId' className='profileImgTop' src={false ? imageExists(`${backendUrl}/img/${auth?.user}`) : defaultIcon}/>
                                    {false ? <input id='file-input-profile' type="file" className="profileImginput" accept="image/*" onChange={(e) => {uploadProfileImg(e)}}/> : <></>}
                                </label>
                                <div className='profileTopContainer'>
                                    <div className='profileName'>{auth?.user}</div>
                                    <div className='profileEmail'>{auth?.email}</div>
                                </div>
                            </div>
                            <SingUp profile={true}/>
                        </>
                    : path === '/profile/affiliate' ?
                        <>
                        <div style={{opacity: "1"}}>
                            <p style={{fontSize: "1.8em", marginTop: "0.5em", marginBottom: "0.3em"}}>Affiliate Program</p>
                            <p style={{fontSize: "1em", marginBlock: "0.3em"}}>The affiliate program allows you to earn money by attracting users to our service. With each purchase made using your individual affiliate link, you receive 15% to your referral account.</p>
                            <ul>
                                <li><img style={{width: "1em", marginRight: "0.3em"}} src={WomanAndMan}/>Invite friends and acquaintances to join us.</li>
                                <li><img style={{width: "1em", marginRight: "0.3em"}} src={ShoppingBags}/>Get bonuses to pay for future purchases.</li>
                                <li><img style={{width: "1em", marginRight: "0.3em"}} src={DollarBanknote}/>If you are Self-employed, sole prop rietor or LLC, then you can withdraw the earned funds to your bank account without commission.</li>
                                <li><img style={{width: "1em", marginRight: "0.3em"}} src={Warning}/>Before joining, be sure to read the terms of the referral program.</li>
                            </ul>
                            <p style={{fontSize: "1.5em", marginTop: "0.2em", marginBottom: "0.3em"}}>How to join?</p>
                            <p style={{fontSize: "1.2em", marginTop: "0.3em", marginBottom: "0.5em"}}>Confirm that you have read the terms of the affiliate program.</p>
                            <p style={{fontSize: "1em", marginTop: "0.3em", marginBottom: "0.3em"}}><input onChange={(e) => {handleAffiliateCheckbox(e)}} type="checkbox"></input> I have read the terms of the referral program and accept them.</p>
                            <div id='AffiliateButton' className='affiliateButton'>Join</div>

                            <p style={{fontSize: "1.5em", marginTop: "0.6em", marginBottom: "0.3em"}}>Your affiliate link: fatisarcanum.xyz/sing-up/{auth.user}</p>
                            <p style={{fontSize: "1.5em", marginTop: "0.3em", marginBottom: "0.2em"}}>Your balance: {balance}$</p>
                            <div className='premiumButton' style={{opacity: "0.7"}}>Withdraw</div>
                        </div>
                        </>
                    : path === '/profile/bonusAcc' ?
                        <>
                            <p style={{fontSize: "1.8em", marginTop: "2em", marginBottom: "0.3em"}}>{trans('TemporarilyUnavailable')}</p>
                            <div style={{opacity: "0.4"}}>
                                <p style={{fontSize: "1.8em", marginTop: "0.5em", marginBottom: "0.3em"}}>{trans('BonusAccount')}</p>
                                <p style={{fontSize: "1.4em", marginBlock: "0.3em", color: "#C977D6"}}>What bonuses are awarded for:</p>
                                <ul>
                                    <li style={{marginBlock: "0.3em"}}>Cashback of 10% on any purchases.</li>
                                    <li style={{marginBlock: "0.3em"}}>444 bonuses for a calculator review.</li>
                                    <li style={{marginBlock: "0.3em"}}>777 bonuses for a course review.</li>
                                </ul>
                                <p style={{fontSize: "1.4em", marginTop: "0.5em", marginBottom: "0.3em", color: "#C977D6"}}>How to use:</p>
                                <p style={{fontSize: "1em", marginBlock: "0.8em", marginLeft: "2em", width: "85%"}}>For any future purchases of calculator access or courses. Bonuses can be used to partially or fully pay for the purchase.</p>
                                <a className='linkHref' style={{fontSize: "1em", marginBlock: "1em", marginLeft: "2em", width: "85%"}}>Full terms of the bonus program.</a>
                                <p style={{fontSize: "1.4em", marginTop: "0.5em", marginBottom: "0.3em", color: "#C977D6"}}>Balance history</p>
                                <p style={{fontSize: "1em", marginBlock: "0.8em", marginLeft: "2em", width: "85%"}}>There is nothing yet...</p>
                            </div>
                        </>
                    : path === '/profile/tariff' ?
                    <>
                        <p style={{fontSize: "1.8em", marginTop: "2em", marginBottom: "0.3em"}}>{trans('Premium')} {premium && new Date() < premium ? <><div style={{color: "green", display: "inline"}}>{trans('active')}</div> {trans('until')} {format(premium, 'dd.MM.yyyy')}</> : <><div style={{color: "red", display: "inline"}}>{trans('inactive')}</div></> }</p>
                        {premium && new Date() < premium ? <p style={{fontSize: "1.1em", marginTop: "0.3em", marginBottom: "0.3em"}}>{trans('PremiumCancelationProcess')}</p> : <></>}
                        <div className='premiumForm'>
                            <div className='headerFont premiumTitle'>{trans('PremiumSubscription')}</div>
                            <ul className='forthFont'>
                                <li style={{marginBlock: "0.3em"}}>{trans('PremiumDesc1')}</li>
                                <li style={{marginBlock: "0.3em"}}>{trans('PremiumDesc2')}</li>
                                <li style={{marginBlock: "0.3em"}}>{trans('PremiumDesc3')}</li>
                                <li style={{marginBlock: "0.3em"}}>{trans('PremiumDesc4')}</li>
                                <li style={{marginBlock: "0.3em"}}>{trans('Renewal')}</li>
                            </ul>
                            <div className='premiumButton' onClick={(e) => {openStripeLink(e)}}>{premium && new Date() < premium ? <>{trans('active')}</> : <>5$ / {trans('month')}</>}</div>
                        </div>
                    </>
                    :
                        <>
                        
                        </>
                    }
                    

                </div>
            </div>
        </div>
    );
}

export default Profile;