import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { NavLink as Link } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import { useLanguage } from '../../context/LanguageContext';
import logo from "./logo.ico";
import "./index.css"
import { Nav, NavLink, NavLinkSignup, NavMenu } from "./NavbarElements";
import chaticon from "../../pages/resources/chaticon.png";
import globalicon from "../../pages/resources/globalicon.png";
import usericon from "../../pages/resources/usericon.png";
import Chat from "../chat/chat.js"

const Navbar = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const { auth } = useContext(AuthContext);
    const { language, changeLanguage, trans } = useLanguage();

    const handleLanguageChange = (event, value) => {
        changeLanguage(value);
        toggleLanguageSelect(event);
    };

    function handleScroll(event) {
        var scrollY = window.scrollY;
        var element = document.getElementById("buttonContainerTop");
        if (scrollY > 50) {
            element.style.backgroundColor = '#000810e7';
        } else {
            element.style.backgroundColor = 'transparent';
        }
    }
    function toggleLanguageSelect(event) {
        var element = document.getElementById("languageToggleTarget");
        element.classList.toggle('activeLanguageSelect');
    }

    function handleDropdownChat(event) {
        if(!auth?.user){
            navigate("/login");
            return;
        }
        let chat = document.getElementById("dropdown-chat");
        if(chat.style.display === "none" || chat.style.display === '') {
            chat.style.display = "block";
        } else {
            chat.style.display = "none";
        }
    }

    useEffect(() => {
        if(location.pathname === "/login" || location.pathname === "/sign-up"){
            document.getElementById("buttonContainerTop").style.backgroundColor = '#00081047';
        } else {
            document.getElementById("buttonContainerTop").style.backgroundColor = 'transparent';
        }
      }, [location.pathname]);

    useEffect(() => {

        window.addEventListener('scroll', handleScroll);

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return (
        <>
            <Nav>
                <NavMenu>
                    <div id="buttonContainerTop">
                        <div className="navlinksContainer">
                            <NavLink className="linkButtonTop" to="/">
                                {trans('Home')}
                            </NavLink>
                            <NavLink className="linkButtonTop" to="/matrix">
                                {trans('Matrix')}
                            </NavLink>
                            <NavLink className="linkButtonTop" to="/taro">
                                <b>{trans('Taro')}</b>
                            </NavLink>
                            <NavLink className="linkButtonTop" to="/chakras">
                                <b>{trans('Chakras')}</b>
                            </NavLink>
                            <NavLink className="linkButtonTop" to="/404">
                                <b>{trans('Blog')}</b>
                            </NavLink>
                            <NavLink className="linkButtonTop" to="/contact">
                                <b>{trans('Contact')}</b>
                            </NavLink>
                        </div>
                        <div className="navbar-buttons">
                            <div className="buttonchik">
                                <img style={{width: "min(5vw, 58px)", zIndex: "1"}} src={globalicon} alt="Global Icon" onClick={(e) => {toggleLanguageSelect(e)}}/>
                                <div id="languageToggleTarget" className="languageBlock">
                                    <div className="languageButton" onClick={(e) => {handleLanguageChange(e, 'en')}}>EN</div>
                                    <div className="languageButton" onClick={(e) => {handleLanguageChange(e, 'ru')}}>RU</div>
                                </div>
                            </div>

                            <div className="buttonchik">
                                <img style={{ width: "min(5vw, 58px)" }} src={chaticon} alt="Chat Icon" onClick={(e) => {handleDropdownChat(e)}}/>
                                <div id="dropdown-chat">
                                    <Chat/>
                                </div>
                            </div>

                            <div className="buttonchik">
                                <Link style={{textDecoration: "none"}} to="/profile/"><img style={{width: "min(5vw, 58px)"}} src={usericon} alt="User Icon" /></Link>
                            </div>
                        </div>
                    </div>
                </NavMenu>
            </Nav >
        </>
    );
};

export default Navbar;