import { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [ auth, setAuth ] = useState({});
    //
    const [ preferences, setPreferences ] = useState({});

    const [ backendUrl, setBackendUrl ] = useState("https://serverfatisarcanum.azurewebsites.net/");

    const [ secondUserName, setSecondUserName ] = useState();

    useEffect(() => {
        const savedPreferences = JSON.parse(localStorage.getItem("preferences"));
        if (savedPreferences) {
          setPreferences(savedPreferences);
        }
        const savedSecondUserName = JSON.parse(localStorage.getItem("secondUserName"));
        if (savedSecondUserName) {
          setSecondUserName(savedSecondUserName);
        }
        const savedAuth = JSON.parse(localStorage.getItem("auth"));
        if (savedAuth) {
          setAuth(savedAuth);
        }
      }, []);

    //

    return (
        <AuthContext.Provider value={{ auth, setAuth, preferences, setPreferences, backendUrl, secondUserName, setSecondUserName }}>
            {children}
        </AuthContext.Provider>
    )
}


export default AuthContext;

export const useAuth = () => {
  return useContext(AuthContext);
};