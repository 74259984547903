import React, {useContext, useEffect, useState, useRef} from "react";
import { NavLink as Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./home.css";
import matrixImageMainPage from "./resources/girl-purple3.png";
import taroImageMainPage from "./resources/taroImagemainPage.png";
import meditationsMainPage from "./resources/matrixImageMainPage.png";
import { useLanguage } from '../context/LanguageContext';
   
const Home = () => {

  const { language, changeLanguage, trans } = useLanguage();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith('/en')) {
      changeLanguage('en');
    } else if (location.pathname.endsWith('/ru')) {
      changeLanguage('ru');
    }
  }, [location, changeLanguage]);


  return (
    <div id="mainContainer">
      <div id="backgroundID">
        <div className="SubContainer" id="topContainer">
          <div className="contentLeft">
            <div className="contentHeader">
              {trans('MatrixofDestiny')}
            </div>
            <div className="contentText">
              {trans('MainPageText1')}
            </div>
            <Link style={{textDecoration: "none"}} to="/matrix"><div class="contentButton">{trans('Calculate')}</div></Link>
          </div>
          <img id="matrixImage" src={matrixImageMainPage}></img>
        </div>
        <div className="SubContainer">
          <div className="contentRight">
            <div className="contentHeader">
              {trans('Taro')}
            </div>
            <div className="contentText">
              {trans('MainPageText2')}
            </div>
            <Link style={{textDecoration: "none"}} to="/taro"><div class="contentButton">{trans('Learn')}</div></Link>
          </div>
          <img id="taroImage" src={taroImageMainPage}></img>
        </div>
        <div className="SubContainer">
          <div className="contentLeft">
            <div className="contentHeader">
              {trans('Meditations')}
            </div>
            <div className="contentText">
              {trans('MainPageText3')}
            </div>
            <div className="contentButton">{trans('Listen')}</div>
          </div>
          <img id="meditationsImage" src={meditationsMainPage}></img>
        </div>
      </div>
    </div>
  );
};

export default Home;

