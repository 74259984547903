import React, {useContext, useEffect, useState, useRef} from "react";
import { NavLink as Link } from "react-router-dom";
import "./chakras.css";
import matrixImageMainPage from "./resources/girl-purple3.png";
import taroImageMainPage from "./resources/taroImagemainPage.png";
import meditationsMainPage from "./resources/matrixImageMainPage.png";
import { useLanguage } from '../context/LanguageContext';
   
const Chakras = () => {
  const { language, changeLanguage, trans } = useLanguage();

  if(language == 'ru'){
    return (
      <div className="chakrasContainer">
        <h1>Чакры: Энергетические Центры Человека</h1>

        <div class="chakra">
            <h2>1. Муладхара (Корневая Чакра)</h2>
            <p><strong>Расположение:</strong> Основание позвоночника.</p>
            <p><strong>Цвет:</strong> Красный.</p>
            <p><strong>Элемент:</strong> Земля.</p>
            <p><strong>Основные функции:</strong> Муладхара отвечает за чувство безопасности, стабильности и выживания. Эта чакра связана с нашими основными инстинктами и физическим благополучием.</p>
            <p><strong>Дисбаланс:</strong> Может проявляться как страх, беспокойство, чувство неуверенности.</p>
        </div>

        <div class="chakra">
            <h2>2. Свадхистана (Сакральная Чакра)</h2>
            <p><strong>Расположение:</strong> Немного ниже пупка.</p>
            <p><strong>Цвет:</strong> Оранжевый.</p>
            <p><strong>Элемент:</strong> Вода.</p>
            <p><strong>Основные функции:</strong> Свадхистана связана с сексуальностью, креативностью и эмоциональными желаниями. Она отвечает за наши чувственные удовольствия и способность наслаждаться жизнью.</p>
            <p><strong>Дисбаланс:</strong> Может выражаться в подавлении эмоций, проблемах с интимностью и творческими блоками.</p>
        </div>

        <div class="chakra">
            <h2>3. Манипура (Чакра Солнечного Сплетения)</h2>
            <p><strong>Расположение:</strong> Область солнечного сплетения, чуть выше пупка.</p>
            <p><strong>Цвет:</strong> Желтый.</p>
            <p><strong>Элемент:</strong> Огонь.</p>
            <p><strong>Основные функции:</strong> Манипура отвечает за силу воли, личную силу и уверенность в себе. Это центр нашей личности и самооценки.</p>
            <p><strong>Дисбаланс:</strong> Проявляется как чувство беспомощности, низкая самооценка, агрессия или чрезмерный контроль.</p>
        </div>

        <div class="chakra">
            <h2>4. Анахата (Сердечная Чакра)</h2>
            <p><strong>Расположение:</strong> Центр груди, около сердца.</p>
            <p><strong>Цвет:</strong> Зеленый.</p>
            <p><strong>Элемент:</strong> Воздух.</p>
            <p><strong>Основные функции:</strong> Анахата ассоциируется с любовью, состраданием и эмоциональным балансом. Она является мостом между нижними и верхними чакрами.</p>
            <p><strong>Дисбаланс:</strong> Может привести к эмоциональной нестабильности, трудностям в отношениях и недостатку эмпатии.</p>
        </div>

        <div class="chakra">
            <h2>5. Вишудха (Горловая Чакра)</h2>
            <p><strong>Расположение:</strong> Горло, область шеи.</p>
            <p><strong>Цвет:</strong> Голубой.</p>
            <p><strong>Элемент:</strong> Эфир.</p>
            <p><strong>Основные функции:</strong> Вишудха отвечает за коммуникацию, самовыражение и правду. Она позволяет нам выражать свои мысли и говорить свою внутреннюю истину.</p>
            <p><strong>Дисбаланс:</strong> Может проявляться в виде проблем с общением, лжи и трудностей в самовыражении.</p>
        </div>

        <div class="chakra">
            <h2>6. Аджна (Чакра Третьего Глаза)</h2>
            <p><strong>Расположение:</strong> Центр лба, между бровями.</p>
            <p><strong>Цвет:</strong> Индиго.</p>
            <p><strong>Элемент:</strong> Свет.</p>
            <p><strong>Основные функции:</strong> Аджна является центром интуиции, проницательности и духовного осознания. Она управляет нашей способностью видеть целостную картину и получать доступ к глубокой мудрости.</p>
            <p><strong>Дисбаланс:</strong> Может проявляться как отсутствие прозорливости, замешательство и оторванность от внутреннего руководства.</p>
        </div>

        <div class="chakra">
            <h2>7. Сахасрара (Коронная Чакра)</h2>
            <p><strong>Расположение:</strong> Макушка головы.</p>
            <p><strong>Цвет:</strong> Фиолетовый или Белый.</p>
            <p><strong>Элемент:</strong> Сознание.</p>
            <p><strong>Основные функции:</strong> Сахасрара представляет собой связь с божественным, высшим сознанием и просветлением. Она является источником духовного пробуждения и вселенского знания.</p>
            <p><strong>Дисбаланс:</strong> Может привести к чувству оторванности от духовности, отсутствию цели или материализму.</p>
        </div>
      </div>
    );
  } else {
    return(
      <div className="chakrasContainer">
        <h1>Chakras: The Energy Centers of the Human Body</h1>

        <div class="chakra">
            <h2>1. Muladhara (Root Chakra)</h2>
            <p><strong>Location:</strong> Base of the spine.</p>
            <p><strong>Color:</strong> Red.</p>
            <p><strong>Element:</strong> Earth.</p>
            <p><strong>Main Functions:</strong> Muladhara governs our sense of security, stability, and survival. It is associated with our primal instincts and physical well-being.</p>
            <p><strong>Imbalance:</strong> Can manifest as fear, anxiety, and insecurity.</p>
        </div>

        <div class="chakra">
            <h2>2. Svadhisthana (Sacral Chakra)</h2>
            <p><strong>Location:</strong> Just below the navel.</p>
            <p><strong>Color:</strong> Orange.</p>
            <p><strong>Element:</strong> Water.</p>
            <p><strong>Main Functions:</strong> Svadhisthana is linked to sexuality, creativity, and emotional desires. It is responsible for our sensual pleasures and ability to enjoy life.</p>
            <p><strong>Imbalance:</strong> May manifest as suppressed emotions, intimacy issues, and creative blocks.</p>
        </div>

        <div class="chakra">
            <h2>3. Manipura (Solar Plexus Chakra)</h2>
            <p><strong>Location:</strong> Solar plexus area, just above the navel.</p>
            <p><strong>Color:</strong> Yellow.</p>
            <p><strong>Element:</strong> Fire.</p>
            <p><strong>Main Functions:</strong> Manipura governs willpower, personal power, and self-confidence. It is the center of our personality and self-esteem.</p>
            <p><strong>Imbalance:</strong> Can show up as feelings of helplessness, low self-esteem, aggression, or excessive control.</p>
        </div>

        <div class="chakra">
            <h2>4. Anahata (Heart Chakra)</h2>
            <p><strong>Location:</strong> Center of the chest, near the heart.</p>
            <p><strong>Color:</strong> Green.</p>
            <p><strong>Element:</strong> Air.</p>
            <p><strong>Main Functions:</strong> Anahata is associated with love, compassion, and emotional balance. It is the bridge between the lower and upper chakras.</p>
            <p><strong>Imbalance:</strong> Can lead to emotional instability, difficulties in relationships, and lack of empathy.</p>
        </div>

        <div class="chakra">
            <h2>5. Vishuddha (Throat Chakra)</h2>
            <p><strong>Location:</strong> Throat area, neck.</p>
            <p><strong>Color:</strong> Blue.</p>
            <p><strong>Element:</strong> Ether.</p>
            <p><strong>Main Functions:</strong> Vishuddha is the center of communication, self-expression, and truth. It enables us to speak our inner truth and express our thoughts clearly.</p>
            <p><strong>Imbalance:</strong> Can result in communication issues, lying, and difficulty in expressing oneself.</p>
        </div>

        <div class="chakra">
            <h2>6. Ajna (Third Eye Chakra)</h2>
            <p><strong>Location:</strong> Center of the forehead, between the eyebrows.</p>
            <p><strong>Color:</strong> Indigo.</p>
            <p><strong>Element:</strong> Light.</p>
            <p><strong>Main Functions:</strong> Ajna is the seat of intuition, insight, and spiritual awareness. It governs our ability to see the bigger picture and access deeper wisdom.</p>
            <p><strong>Imbalance:</strong> May manifest as lack of foresight, confusion, and disconnection from inner guidance.</p>
        </div>

        <div class="chakra">
            <h2>7. Sahasrara (Crown Chakra)</h2>
            <p><strong>Location:</strong> Top of the head.</p>
            <p><strong>Color:</strong> Violet or White.</p>
            <p><strong>Element:</strong> Consciousness.</p>
            <p><strong>Main Functions:</strong> Sahasrara represents the connection to the divine, higher consciousness, and enlightenment. It is the source of spiritual awakening and universal knowledge.</p>
            <p><strong>Imbalance:</strong> Can lead to feelings of disconnection from spirituality, lack of purpose, or materialism.</p>
        </div>
      </div>
    );
  }
  
};

export default Chakras;

