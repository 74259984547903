//import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
  position: absolute;
`;

export const NavLink = Link;

export const NavLinkSignup = styled(Link)`
  color: #ffffff;
  float:left;
  display: flex;
  margin-left: auto; 
  margin-right: 0.5rem;
  text-decoration: none;
  padding: 0.3rem 0.5rem;
  height: 100%;
  cursor: pointer;
  border-radius: 8px;
  background-color: rgba(20, 90, 207, 1);
  &.active {
    background-color: rgb(68, 117, 207);
  }
`;
/*
export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
*/

export const NavMenu = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: flex;
    margin: auto;
    align-items: center;
  }
`;