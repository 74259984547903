import React, { useContext, useState, useEffect } from 'react';
import { useLanguage } from '../context/LanguageContext';
import './CookieConsent.css';

const CookieConsent = () => {
  const [show, setShow] = useState(false);
  const { language, changeLanguage, trans } = useLanguage();

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setShow(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <div className="cookie-consent__content">
        <h2>{trans('TermsTitle')}</h2>
        <p>
          {trans('TermsText1')} <a href="/termsofuse.pdf" target="_blank">{trans('Terms')}</a> {trans('and')} <a href="/privacypolicy.pdf"  target="_blank">{trans('Privacy')}</a> {trans('TermsText2')}
        </p>
        <button onClick={handleAccept}>{trans('Accept')}</button>
      </div>
    </div>
  );
};

export default CookieConsent;