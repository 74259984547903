import React, {useContext, useEffect, useState, useRef} from "react";
import { NavLink as Link } from "react-router-dom";
import "./home.css";
import matrixImageMainPage from "./resources/girl-purple3.png";
import taroImageMainPage from "./resources/taroImagemainPage.png";
import meditationsMainPage from "./resources/matrixImageMainPage.png";
   
const NotFound = () => {

  return (
    <div className="NotFoundContainer">
      Not implemented yet
    </div>
  );
};

export default NotFound;

